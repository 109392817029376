import van, { State } from "vanjs-core";
import { RouteBuilder, routeNavigator } from "../routes/navigator";
import { isValidUsername, UiIcon } from "../bin/utils";
import { getUsers } from "../api/user";
import { FetchType, getPosts } from "../api/post";
import { postBodyElement, postElement } from "../customElements/post-div";
import { LupydHeader } from "../customElements/headers/lupyd-header";
import { followUsers, getFollowedUsersState, unfollowUsers } from "../doc/user";
import { ChatButton, UserProfileElement } from "./userPage";
import { FullPost, PostBody } from "../protos/post";
import * as vanX from "vanjs-ext";
import { User } from "../protos/user";
import store from "store2";

const { span, a, ul, div, p, button, b } = van.tags;

const PostsSearchTab = (query: string) => {
  if (query === "") {
    return div(
      { class: "posts-search-tab" },
      ul({ class: "no-pad no-list-style" }),
    );
  }

  const results = vanX.reactive([] as FullPost[]);

  const postsFuture = getPosts({
    fetchType: FetchType.Search,
    fetchTypeFields: query,
  });

  postsFuture
    .then((posts) => {
      results.push(...posts.map(vanX.noreactive));
    })
    .catch(console.error);

  return div(
    { class: "posts-search-tab" },
    vanX.list(ul({ class: "no-pad no-list-style" }), results, (post) =>
      postElement(post.val),
    ),
  );
};

class UsersSearchTab extends HTMLElement {
  results = vanX.reactive([] as User[]);

  constructor(query: string) {
    super();
    this.setAttribute("query", query);
    this.classList.add("users-search-tab");
  }

  connectedCallback() {
    const query = this.getAttribute("query");
    if (!query || query.length == 0) {
      this.innerText = "query attribute is missing";
      return;
    }
    const usersFuture = getUsers(query!);
    usersFuture.then((users) => {
      this.results.push(...users.map(vanX.noreactive));
    });

    this.replaceChildren(
      vanX.list(ul({ class: "no-pad no-list-style" }), this.results, (user) =>
        userListTile(user.val),
      ),
    );
  }
}

export function userListTile(user: User) {
  const showAbout = van.state(false);
  let aboutElement: HTMLElement | undefined = undefined;
  return div(
    {
      class: () => `user-card ${showAbout.val ? "round_corners" : ""}`,
    },
    div(
      { class: "row user-header" },
      UserProfileElement(user.uname, !user.pfp),
      div(
        { class: "row-center" },
        a(
          {
            class: "no-text-decoration",
            href: `/user/${user.uname}`,
            onclick(event: Event) {
              event.preventDefault(),
                routeNavigator.pushRoute(`/user/${user.uname}`, {
                  user,
                });
            },
          },
          b(user.uname),
        ),
      ),

      FollowUserButton(user.uname),

      user.uname != store.get("username") && user.chats
        ? ChatButton(user.uname, "theme-button")
        : div(),

      button(
        {
          class: "theme-button",
          onclick: () => (showAbout.val = !showAbout.val),
        },
        span(
          {
            class: () => `rotatable ${showAbout.val ? "rotate_up" : ""}`,
          },
          UiIcon("chevron-down"),
        ),
      ),
    ),

    div(
      {
        class: () => `expandable ${showAbout.val ? "expand" : ""}`,
      },
      () => {
        if (!showAbout.val || user.bio.length == 0) {
          return aboutElement ?? div();
        }
        if (!aboutElement) {
          aboutElement = postBodyElement(PostBody.decode(user.bio));
        }
        return aboutElement;
      },
    ),
  );
}

customElements.define("users-search-tab", UsersSearchTab);

export function FollowUserButton(username: string) {
  return store.get("username") == username
    ? undefined
    : () =>
        getFollowedUsersState().val.includes(username)
          ? button(
              {
                class: "theme-button",
                onclick: () => unfollowUsers([username]),
              },
              "Unfollow",
            )
          : button(
              { class: "theme-button", onclick: () => followUsers([username]) },
              "Follow",
            );
}

export const searchPageElement = (q: string) => {
  const onSearchSubmit = (s: State<string>) => {
    if (s.val.trim() !== q.trim() && s.val.trim().length !== 0) {
      routeNavigator.replaceRoute(encodeURI(`/search?q=${s.val}`));
    }
  };

  const header = LupydHeader(van.state(q), onSearchSubmit);

  if (!isValidUsername(q)) {
    return div({ class: "header-pad" }, header, PostsSearchTab(q));
  } else {
    const tabState = van.state(
      store.get("selectedSearchTab") ?? ("users" as "users" | "posts"),
    );

    van.derive(() => store.set("selectedSearchTab", tabState.val));

    let postsTab: HTMLElement | undefined;

    const getPostsTab = van.derive(() => {
      if (!postsTab && tabState.val === "posts") {
        postsTab = PostsSearchTab(q);
      }

      return postsTab ?? div();
    });

    let usersTab: HTMLElement | undefined;
    const getUsersTab = van.derive(() => {
      if (!usersTab && tabState.val === "users") {
        usersTab = new UsersSearchTab(q);
      }
      return usersTab ?? div();
    });
    return div(
      { class: "header-pad" },
      header,

      div(
        { class: "stick" },
        div(
          { class: "row tab-switches" },
          button(
            {
              class: () =>
                `tab-switch ${tabState.val === "users" ? "selected" : ""}`,
              onclick: () => (tabState.val = "users"),
            },
            "Users",
          ),
          button(
            {
              class: () =>
                `tab-switch ${tabState.val === "posts" ? "selected" : ""}`,
              onclick: () => (tabState.val = "posts"),
            },
            "Posts",
          ),
        ),
      ),
      div(
        { class: () => (tabState.val !== "users" ? "hide" : "") },
        () => getUsersTab.val,
      ),
      div(
        { class: () => (tabState.val !== "posts" ? "hide" : "") },
        () => getPostsTab.val,
      ),
    );
  }
};

export const searchPageBuilder: RouteBuilder = async (url, _data) => {
  const uri = new URL(url);

  if (uri.pathname !== "/search") {
    return undefined;
  }

  const query = uri.searchParams.get("q")?.trim();
  if (!query || query.length === 0) {
    return div(p("Search Query is Empty"));
  }

  return searchPageElement(query);
};
