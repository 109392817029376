import van from "vanjs-core";

const { div, h1, p, a } = van.tags;

export const notFoundRouteBuilder = async (_: string) =>
  div(
    { class: "not-found-element" },
    div(
      h1("404"),
      p(`Oops! The page '${_}' you're looking for doesn't exist.`),
      a({ href: "/" }, "Go To Home"),
    ),
  );
