import { UiIcon } from "../../bin/utils";
import { AuthHandler } from "../../firebase/auth";
import { routeNavigator } from "../../routes/navigator";
import { UserProfileElement } from "../../routes/userPage";
import { dropDownMenu } from "../dropdown";
import van from "vanjs-core";
import { Await } from "vanjs-ui";

const { div, a } = van.tags;

export class MyProfileButton extends HTMLElement {
  connectedCallback() {
    this.render();
  }

  render() {
    const userState = AuthHandler.currentUser();

    const getTitle = (username: string | null) =>
      div(
        div(
          { class: "center" },
          username ? UserProfileElement(username) : UiIcon("user"),
        ),
      );

    const getSettings = () => {
      return a(
        {
          class: "no-text-decoration theme-button fill-primary-accent",
          href: "/settings",
          onclick: (_: Event) => {
            _.preventDefault();
            routeNavigator.pushRoute("/settings");
          },
        },
        "Settings",
      );
    };

    const el = div({ class: "center" }, () => {
      const user = userState.val;
      if (user == null) {
        const href = "/signin";
        const loginButton = a(
          {
            class: "no-text-decoration theme-button fill-primary-accent",
            href,
            onclick: (_: Event) => {
              _.preventDefault();
              routeNavigator.pushRoute(href);
            },
          },
          "Sign in",
        );

        const tiles = [loginButton, getSettings()];
        return dropDownMenu(getTitle(null), tiles);
      } else {
        return Await(
          {
            value: AuthHandler.getUsername(user),
            Loading() {
              return div({ class: "loader" });
            },
          },
          (username) => {
            if (username != null) {
              const tiles = [
                // a(
                //   {
                //     class:
                //       "no-text-decoration theme-button fill-primary-accent",
                //     href: `/user/${username}`,
                //     onclick: (e: Event) => {
                //       e.preventDefault();
                //       routeNavigator.pushRoute(`/user/${username}`);
                //     },
                //   },
                //   "My Profile",
                // ),

                getSettings(),

                a(
                  {
                    class:
                      "no-text-decoration theme-button fill-primary-accent",
                    href: `/signout`,
                    onclick: (_: Event) => {
                      _.preventDefault();
                      AuthHandler.signOut();
                    },
                  },
                  "Logout",
                ),
              ];
              return dropDownMenu(getTitle(username), tiles);
            } else {
              const href = "/signin";
              const loginButton = a(
                {
                  class: "no-text-decoration theme-button fill-primary-accent",
                  href,
                  onclick: (_: Event) => {
                    _.preventDefault();
                    routeNavigator.pushRoute(href);
                  },
                },
                "Sign in",
              );

              const tiles = [loginButton, getSettings()];
              return dropDownMenu(getTitle(username), tiles);
            }
          },
        );
      }
    });
    this.classList.add("lupyd-profile-button");
    this.replaceChildren(el);
  }
}

customElements.define("lupyd-profile-button", MyProfileButton);
