import { loadingBar } from "../bin/topbar";
import van from "vanjs-core";

// undefined if url don't match
export type RouteBuilder = (
  url: string,
  data?: any,
) => Promise<HTMLElement | undefined>;

export class RouteNavigator {
  private routeBuilders: RouteBuilder[];
  private notFoundPageBuilder: RouteBuilder;
  private errorPageBuilder: RouteBuilder;
  private mainElement: HTMLElement;

  constructor(
    mainElement: HTMLElement,
    notFoundPageBuilder: RouteBuilder,
    errorPageBuilder: RouteBuilder,
  ) {
    this.routeBuilders = [];
    this.notFoundPageBuilder = notFoundPageBuilder;
    this.errorPageBuilder = errorPageBuilder;
    this.mainElement = mainElement;
  }

  addRouteBuilder(builder: RouteBuilder) {
    this.routeBuilders.push(builder);
  }

  currentPageElement(): HTMLElement | null {
    return this.mainElement.lastElementChild as HTMLElement | null;
  }

  async pushRoute(url: string, data?: any) {
    if (url.startsWith("/")) {
      url = `${location.origin}${url}`;
    }
    loadingBar.load();
    const el = await this.buildPage(url, data);
    this.pushPageAndState(url, el);
    loadingBar.finish();
  }

  async refreshRoute() {
    this.replaceRoute(window.location.href);
  }

  async replaceRoute(url: string, data?: any) {
    if (url.startsWith("/")) {
      url = `${location.origin}${url}`;
    }
    loadingBar.load();

    const el = await this.buildPage(url, data);
    this.replacePageAndState(url, el);
    loadingBar.finish();
  }

  private showOverlay() {
    document
      .querySelector(".overlays")!
      .append(van.tags.div({ class: "overlay show" }));
    // (document.querySelector("#overlay")! as HTMLElement).style.display = "flex";
  }

  private hideOverlay() {
    document.querySelector(".overlays")!.replaceChildren();
    // (document.querySelector("#overlay")! as HTMLElement).style.display = "none";
  }

  async buildPage(url: string, data?: any): Promise<HTMLElement> {
    try {
      this.showOverlay();
      for (const routeBuilder of this.routeBuilders) {
        const el = await routeBuilder(url, data);
        if (el) {
          loadingBar.finish();
          this.hideOverlay();
          return el;
        }
      }
      const el = await this.notFoundPageBuilder(url, data)!;
      this.hideOverlay();
      return el!;
    } catch (err) {
      console.error("Element building failed ", err);
      const el = await this.errorPageBuilder(url, { err, ...data });
      this.hideOverlay();
      return el!;
    }
  }

  replaceCurrentPage(page: HTMLElement) {
    const lastChild = this.mainElement.lastElementChild;
    if (lastChild) {
      lastChild.replaceWith(page);
    } else {
      this.mainElement.appendChild(page);
    }
  }

  addPage(page: HTMLElement) {
    const lastChild = this.mainElement.lastElementChild;
    if (lastChild) {
      (lastChild as HTMLElement).style.display = "none";
    }
    this.mainElement.appendChild(page);
  }

  pushPageAndState(url: string, page: HTMLElement) {
    const lastChild = this.mainElement.lastElementChild as
      | HTMLElement
      | undefined;
    let level = 0;
    if (lastChild) {
      level = Number(lastChild.getAttribute("level")) + 1;
      lastChild.style.display = "none";
    }
    page.setAttribute("level", `${level}`);
    page.setAttribute("url", url);
    this.mainElement.appendChild(page);
    history.pushState({ level, url }, "", url);
  }

  replacePageAndState(url: string, page: HTMLElement) {
    const lastChild = this.mainElement.lastElementChild as
      | HTMLElement
      | undefined;
    let level = 0;
    if (lastChild) {
      level = Number(lastChild.getAttribute("level")) + 1;
      history.replaceState({ level, url }, "", url);
      lastChild.replaceWith(page);
    } else {
      history.replaceState({ level, url }, "", url);
      this.mainElement.appendChild(page);
    }
    page.setAttribute("level", `${level}`);
    page.setAttribute("url", url);
  }

  async updatePage() {
    loadingBar.load();
    console.log({ state: history.state });
    if (history.state) {
      if (
        typeof history.state.level === "number" &&
        typeof history.state.url === "string"
      ) {
        const stateLevel = history.state.level as number;
        const stateUrl = history.state.url as string;
        const children = Array.from(this.mainElement.children) as HTMLElement[];
        let removeNextChildrens = false;
        for (let i = 0; i < children.length; i++) {
          const child = children[i];
          if (removeNextChildrens) {
            child.remove();
            console.log(`Removed because removeNextChildren is true`, {
              child,
              i,
            });
          } else {
            const childLevel = Number(child.getAttribute("level"));
            const childUrl = child.getAttribute("url")!;
            if (childLevel == stateLevel) {
              if (i != 0) {
                children[i - 1].style.display = "none";
                child.style.display = "";
                console.log(`Set ${i - 1} child display to none`, { i, child });
              } else {
                if (childUrl === stateUrl) {
                  child.style.display = "";
                  console.log(`Set child display to ""`, { i, child });
                } else {
                  const el = await this.buildPage(location.href, {
                    level: stateLevel,
                  });
                  console.log(`Built a new page and replacing state `, {
                    el,
                    i,
                    child,
                  });
                  this.replacePageAndState(stateUrl, el);
                }
              }
              removeNextChildrens = true;
            } else if (childLevel > stateLevel) {
              const el = await this.buildPage(location.href, {
                level: stateLevel,
              });
              child.remove();
              console.log(`Removed because childLevel > stateLevel`, {
                child,
                childLevel,
                stateLevel,
                i,
              });
              if (i != 0) {
                children[i - 1].style.display = "none";
              }
              el.setAttribute("level", stateLevel.toString());
              this.mainElement.appendChild(el);
              removeNextChildrens = true;
            }
          }
        }
        if (!removeNextChildrens) {
          const el = await this.buildPage(location.href, { level: stateLevel });
          if (children.length != 0) {
            children[children.length - 1].style.display = "none";
          }
          el.setAttribute("level", stateLevel.toString());
          this.addPage(el);
        }
      } else {
        console.error("Invalid State ", history);
        const el = await this.buildPage(location.href);
        el.setAttribute("level", "0");
        this.mainElement.replaceChildren(el);
      }
    } else {
      console.error("No State Present ", history);
      const el = await this.buildPage(location.href);
      el.setAttribute("level", "0");
      this.mainElement.replaceChildren(el);
    }
    loadingBar.finish();
  }
}

const errorBuilder = async (_: string, data?: any) => {
  const div = document.createElement("div");
  div.innerText = `Unable to build route ${_}\n `;
  if (data && data.err && data.err instanceof Error) {
    div.innerText += data.err.stack;
  }
  return div;
};
const notFoundBuilder = async (_: string) => {
  const div = document.createElement("div");
  div.innerText = `Route ${_} not found`;
  return div;
};

export const routeNavigator = new RouteNavigator(
  document.getElementById("main")!,
  notFoundBuilder,
  errorBuilder,
);
