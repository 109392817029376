// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.0
//   protoc               v5.27.3
// source: post.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import { Elements } from "./lupyd-md";
import Long = require("long");

export const protobufPackage = "lupyd.post";

export enum postType {
  NOT_DEFINED = 0,
  SAFE = 1,
  ANONYMOUS = 2,
  NSFW = 4,
  DANGEROUS = 8,
  UNRECOGNIZED = -1,
}

export function postTypeFromJSON(object: any): postType {
  switch (object) {
    case 0:
    case "NOT_DEFINED":
      return postType.NOT_DEFINED;
    case 1:
    case "SAFE":
      return postType.SAFE;
    case 2:
    case "ANONYMOUS":
      return postType.ANONYMOUS;
    case 4:
    case "NSFW":
      return postType.NSFW;
    case 8:
    case "DANGEROUS":
      return postType.DANGEROUS;
    case -1:
    case "UNRECOGNIZED":
    default:
      return postType.UNRECOGNIZED;
  }
}

export function postTypeToJSON(object: postType): string {
  switch (object) {
    case postType.NOT_DEFINED:
      return "NOT_DEFINED";
    case postType.SAFE:
      return "SAFE";
    case postType.ANONYMOUS:
      return "ANONYMOUS";
    case postType.NSFW:
      return "NSFW";
    case postType.DANGEROUS:
      return "DANGEROUS";
    case postType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

/** nullable boolean, because proto3 made some weird decisions */
export interface BoolValue {
  val: boolean;
}

export interface PostBody {
  plainText?: string | undefined;
  markdown?: string | undefined;
  elements?: Elements | undefined;
}

export interface PostBodies {
  /** Edits will be appended */
  bodies: PostBody[];
}

export interface FullPost {
  /** required */
  id: Uint8Array;
  title: string;
  by: string;
  /** Bit Enum so can't use regular protobuf Enum to represent it */
  postType: number;
  expiry: bigint;
  /** replying to the post id, becomes child of it, and will expire once the parent expires */
  replyingTo: Uint8Array;
  /** PostBodies but is stored as bytes in the lupyd database (postgres), so instead of reconstructing we just use byte representation and rely on protobuf parser on client and server side, not a good idea */
  body: Uint8Array;
  replies: bigint;
  upvotes: bigint;
  downvotes: bigint;
  /** to preserve your posts privately only to the user */
  isMemory: boolean;
  vote: BoolValue | undefined;
}

export interface CreatePostDetails {
  title: string;
  body: PostBody | undefined;
  expiry: bigint;
  postType: number;
  isMemory: boolean;
  replyingTo: Uint8Array;
  files: string[];
  /** body and files will be appended to previous post, everything else is ignored */
  editingFrom: Uint8Array;
}

export interface CreatePostWithFiles {
  /** required */
  fields: CreatePostDetails | undefined;
  files: File[];
}

export interface File {
  name: string;
  mimeType: string;
  length: bigint;
}

export interface GetPostsData {
  allowedPostTypes: number;
  by: string[];
  allPosts: boolean;
  cursor: Uint8Array;
  tags: string;
}

export interface FullPosts {
  posts: FullPost[];
}

export interface Votes {
  votes: Vote[];
}

export interface Vote {
  id: Uint8Array;
  val: BoolValue | undefined;
}

export interface PostIds {
  ids: Uint8Array[];
}

export interface CreateFileFields {
  expiry: bigint;
  by: string;
  files: string[];
}

export interface PollVote {
  postId: Uint8Array;
  optionId: number;
}

export interface PollOption {
  optionId: number;
  votes: bigint;
}

export interface PollResult {
  pollId: Uint8Array;
  values: number[];
}

export interface PollResults {
  results: PollResult[];
}

export interface CreatePollFields {
  postId: Uint8Array;
  numOfOpts: number;
}

export interface PollUserVote {
  pollId: Uint8Array;
  val: number;
}

export interface PollUserVotes {
  votes: PollUserVote[];
}

export interface PostReport {
  postId: Uint8Array;
  sevirity: number;
  description: string;
}

function createBaseBoolValue(): BoolValue {
  return { val: false };
}

export const BoolValue = {
  encode(message: BoolValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.val !== false) {
      writer.uint32(8).bool(message.val);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): BoolValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseBoolValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.val = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): BoolValue {
    return { val: isSet(object.val) ? globalThis.Boolean(object.val) : false };
  },

  toJSON(message: BoolValue): unknown {
    const obj: any = {};
    if (message.val !== false) {
      obj.val = message.val;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<BoolValue>, I>>(base?: I): BoolValue {
    return BoolValue.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<BoolValue>, I>>(object: I): BoolValue {
    const message = createBaseBoolValue();
    message.val = object.val ?? false;
    return message;
  },
};

function createBasePostBody(): PostBody {
  return { plainText: undefined, markdown: undefined, elements: undefined };
}

export const PostBody = {
  encode(message: PostBody, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.plainText !== undefined) {
      writer.uint32(10).string(message.plainText);
    }
    if (message.markdown !== undefined) {
      writer.uint32(18).string(message.markdown);
    }
    if (message.elements !== undefined) {
      Elements.encode(message.elements, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PostBody {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePostBody();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.plainText = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.markdown = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.elements = Elements.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PostBody {
    return {
      plainText: isSet(object.plainText) ? globalThis.String(object.plainText) : undefined,
      markdown: isSet(object.markdown) ? globalThis.String(object.markdown) : undefined,
      elements: isSet(object.elements) ? Elements.fromJSON(object.elements) : undefined,
    };
  },

  toJSON(message: PostBody): unknown {
    const obj: any = {};
    if (message.plainText !== undefined) {
      obj.plainText = message.plainText;
    }
    if (message.markdown !== undefined) {
      obj.markdown = message.markdown;
    }
    if (message.elements !== undefined) {
      obj.elements = Elements.toJSON(message.elements);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PostBody>, I>>(base?: I): PostBody {
    return PostBody.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PostBody>, I>>(object: I): PostBody {
    const message = createBasePostBody();
    message.plainText = object.plainText ?? undefined;
    message.markdown = object.markdown ?? undefined;
    message.elements = (object.elements !== undefined && object.elements !== null)
      ? Elements.fromPartial(object.elements)
      : undefined;
    return message;
  },
};

function createBasePostBodies(): PostBodies {
  return { bodies: [] };
}

export const PostBodies = {
  encode(message: PostBodies, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.bodies) {
      PostBody.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PostBodies {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePostBodies();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.bodies.push(PostBody.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PostBodies {
    return {
      bodies: globalThis.Array.isArray(object?.bodies) ? object.bodies.map((e: any) => PostBody.fromJSON(e)) : [],
    };
  },

  toJSON(message: PostBodies): unknown {
    const obj: any = {};
    if (message.bodies?.length) {
      obj.bodies = message.bodies.map((e) => PostBody.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PostBodies>, I>>(base?: I): PostBodies {
    return PostBodies.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PostBodies>, I>>(object: I): PostBodies {
    const message = createBasePostBodies();
    message.bodies = object.bodies?.map((e) => PostBody.fromPartial(e)) || [];
    return message;
  },
};

function createBaseFullPost(): FullPost {
  return {
    id: new Uint8Array(0),
    title: "",
    by: "",
    postType: 0,
    expiry: 0n,
    replyingTo: new Uint8Array(0),
    body: new Uint8Array(0),
    replies: 0n,
    upvotes: 0n,
    downvotes: 0n,
    isMemory: false,
    vote: undefined,
  };
}

export const FullPost = {
  encode(message: FullPost, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id.length !== 0) {
      writer.uint32(10).bytes(message.id);
    }
    if (message.title !== "") {
      writer.uint32(18).string(message.title);
    }
    if (message.by !== "") {
      writer.uint32(26).string(message.by);
    }
    if (message.postType !== 0) {
      writer.uint32(32).uint32(message.postType);
    }
    if (message.expiry !== 0n) {
      if (BigInt.asUintN(64, message.expiry) !== message.expiry) {
        throw new globalThis.Error("value provided for field message.expiry of type uint64 too large");
      }
      writer.uint32(40).uint64(message.expiry.toString());
    }
    if (message.replyingTo.length !== 0) {
      writer.uint32(50).bytes(message.replyingTo);
    }
    if (message.body.length !== 0) {
      writer.uint32(66).bytes(message.body);
    }
    if (message.replies !== 0n) {
      if (BigInt.asUintN(64, message.replies) !== message.replies) {
        throw new globalThis.Error("value provided for field message.replies of type uint64 too large");
      }
      writer.uint32(72).uint64(message.replies.toString());
    }
    if (message.upvotes !== 0n) {
      if (BigInt.asIntN(64, message.upvotes) !== message.upvotes) {
        throw new globalThis.Error("value provided for field message.upvotes of type int64 too large");
      }
      writer.uint32(80).int64(message.upvotes.toString());
    }
    if (message.downvotes !== 0n) {
      if (BigInt.asIntN(64, message.downvotes) !== message.downvotes) {
        throw new globalThis.Error("value provided for field message.downvotes of type int64 too large");
      }
      writer.uint32(88).int64(message.downvotes.toString());
    }
    if (message.isMemory !== false) {
      writer.uint32(96).bool(message.isMemory);
    }
    if (message.vote !== undefined) {
      BoolValue.encode(message.vote, writer.uint32(106).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FullPost {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFullPost();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.title = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.by = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.postType = reader.uint32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.expiry = longToBigint(reader.uint64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.replyingTo = reader.bytes();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.body = reader.bytes();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.replies = longToBigint(reader.uint64() as Long);
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.upvotes = longToBigint(reader.int64() as Long);
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.downvotes = longToBigint(reader.int64() as Long);
          continue;
        case 12:
          if (tag !== 96) {
            break;
          }

          message.isMemory = reader.bool();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.vote = BoolValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FullPost {
    return {
      id: isSet(object.id) ? bytesFromBase64(object.id) : new Uint8Array(0),
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      by: isSet(object.by) ? globalThis.String(object.by) : "",
      postType: isSet(object.postType) ? globalThis.Number(object.postType) : 0,
      expiry: isSet(object.expiry) ? BigInt(object.expiry) : 0n,
      replyingTo: isSet(object.replyingTo) ? bytesFromBase64(object.replyingTo) : new Uint8Array(0),
      body: isSet(object.body) ? bytesFromBase64(object.body) : new Uint8Array(0),
      replies: isSet(object.replies) ? BigInt(object.replies) : 0n,
      upvotes: isSet(object.upvotes) ? BigInt(object.upvotes) : 0n,
      downvotes: isSet(object.downvotes) ? BigInt(object.downvotes) : 0n,
      isMemory: isSet(object.isMemory) ? globalThis.Boolean(object.isMemory) : false,
      vote: isSet(object.vote) ? BoolValue.fromJSON(object.vote) : undefined,
    };
  },

  toJSON(message: FullPost): unknown {
    const obj: any = {};
    if (message.id.length !== 0) {
      obj.id = base64FromBytes(message.id);
    }
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.by !== "") {
      obj.by = message.by;
    }
    if (message.postType !== 0) {
      obj.postType = Math.round(message.postType);
    }
    if (message.expiry !== 0n) {
      obj.expiry = message.expiry.toString();
    }
    if (message.replyingTo.length !== 0) {
      obj.replyingTo = base64FromBytes(message.replyingTo);
    }
    if (message.body.length !== 0) {
      obj.body = base64FromBytes(message.body);
    }
    if (message.replies !== 0n) {
      obj.replies = message.replies.toString();
    }
    if (message.upvotes !== 0n) {
      obj.upvotes = message.upvotes.toString();
    }
    if (message.downvotes !== 0n) {
      obj.downvotes = message.downvotes.toString();
    }
    if (message.isMemory !== false) {
      obj.isMemory = message.isMemory;
    }
    if (message.vote !== undefined) {
      obj.vote = BoolValue.toJSON(message.vote);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FullPost>, I>>(base?: I): FullPost {
    return FullPost.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FullPost>, I>>(object: I): FullPost {
    const message = createBaseFullPost();
    message.id = object.id ?? new Uint8Array(0);
    message.title = object.title ?? "";
    message.by = object.by ?? "";
    message.postType = object.postType ?? 0;
    message.expiry = object.expiry ?? 0n;
    message.replyingTo = object.replyingTo ?? new Uint8Array(0);
    message.body = object.body ?? new Uint8Array(0);
    message.replies = object.replies ?? 0n;
    message.upvotes = object.upvotes ?? 0n;
    message.downvotes = object.downvotes ?? 0n;
    message.isMemory = object.isMemory ?? false;
    message.vote = (object.vote !== undefined && object.vote !== null) ? BoolValue.fromPartial(object.vote) : undefined;
    return message;
  },
};

function createBaseCreatePostDetails(): CreatePostDetails {
  return {
    title: "",
    body: undefined,
    expiry: 0n,
    postType: 0,
    isMemory: false,
    replyingTo: new Uint8Array(0),
    files: [],
    editingFrom: new Uint8Array(0),
  };
}

export const CreatePostDetails = {
  encode(message: CreatePostDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.title !== "") {
      writer.uint32(10).string(message.title);
    }
    if (message.body !== undefined) {
      PostBody.encode(message.body, writer.uint32(18).fork()).ldelim();
    }
    if (message.expiry !== 0n) {
      if (BigInt.asUintN(64, message.expiry) !== message.expiry) {
        throw new globalThis.Error("value provided for field message.expiry of type uint64 too large");
      }
      writer.uint32(24).uint64(message.expiry.toString());
    }
    if (message.postType !== 0) {
      writer.uint32(32).int32(message.postType);
    }
    if (message.isMemory !== false) {
      writer.uint32(40).bool(message.isMemory);
    }
    if (message.replyingTo.length !== 0) {
      writer.uint32(50).bytes(message.replyingTo);
    }
    for (const v of message.files) {
      writer.uint32(58).string(v!);
    }
    if (message.editingFrom.length !== 0) {
      writer.uint32(66).bytes(message.editingFrom);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreatePostDetails {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreatePostDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.title = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.body = PostBody.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.expiry = longToBigint(reader.uint64() as Long);
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.postType = reader.int32();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.isMemory = reader.bool();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.replyingTo = reader.bytes();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.files.push(reader.string());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.editingFrom = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreatePostDetails {
    return {
      title: isSet(object.title) ? globalThis.String(object.title) : "",
      body: isSet(object.body) ? PostBody.fromJSON(object.body) : undefined,
      expiry: isSet(object.expiry) ? BigInt(object.expiry) : 0n,
      postType: isSet(object.postType) ? globalThis.Number(object.postType) : 0,
      isMemory: isSet(object.isMemory) ? globalThis.Boolean(object.isMemory) : false,
      replyingTo: isSet(object.replyingTo) ? bytesFromBase64(object.replyingTo) : new Uint8Array(0),
      files: globalThis.Array.isArray(object?.files) ? object.files.map((e: any) => globalThis.String(e)) : [],
      editingFrom: isSet(object.editingFrom) ? bytesFromBase64(object.editingFrom) : new Uint8Array(0),
    };
  },

  toJSON(message: CreatePostDetails): unknown {
    const obj: any = {};
    if (message.title !== "") {
      obj.title = message.title;
    }
    if (message.body !== undefined) {
      obj.body = PostBody.toJSON(message.body);
    }
    if (message.expiry !== 0n) {
      obj.expiry = message.expiry.toString();
    }
    if (message.postType !== 0) {
      obj.postType = Math.round(message.postType);
    }
    if (message.isMemory !== false) {
      obj.isMemory = message.isMemory;
    }
    if (message.replyingTo.length !== 0) {
      obj.replyingTo = base64FromBytes(message.replyingTo);
    }
    if (message.files?.length) {
      obj.files = message.files;
    }
    if (message.editingFrom.length !== 0) {
      obj.editingFrom = base64FromBytes(message.editingFrom);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreatePostDetails>, I>>(base?: I): CreatePostDetails {
    return CreatePostDetails.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreatePostDetails>, I>>(object: I): CreatePostDetails {
    const message = createBaseCreatePostDetails();
    message.title = object.title ?? "";
    message.body = (object.body !== undefined && object.body !== null) ? PostBody.fromPartial(object.body) : undefined;
    message.expiry = object.expiry ?? 0n;
    message.postType = object.postType ?? 0;
    message.isMemory = object.isMemory ?? false;
    message.replyingTo = object.replyingTo ?? new Uint8Array(0);
    message.files = object.files?.map((e) => e) || [];
    message.editingFrom = object.editingFrom ?? new Uint8Array(0);
    return message;
  },
};

function createBaseCreatePostWithFiles(): CreatePostWithFiles {
  return { fields: undefined, files: [] };
}

export const CreatePostWithFiles = {
  encode(message: CreatePostWithFiles, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.fields !== undefined) {
      CreatePostDetails.encode(message.fields, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.files) {
      File.encode(v!, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreatePostWithFiles {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreatePostWithFiles();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.fields = CreatePostDetails.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.files.push(File.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreatePostWithFiles {
    return {
      fields: isSet(object.fields) ? CreatePostDetails.fromJSON(object.fields) : undefined,
      files: globalThis.Array.isArray(object?.files) ? object.files.map((e: any) => File.fromJSON(e)) : [],
    };
  },

  toJSON(message: CreatePostWithFiles): unknown {
    const obj: any = {};
    if (message.fields !== undefined) {
      obj.fields = CreatePostDetails.toJSON(message.fields);
    }
    if (message.files?.length) {
      obj.files = message.files.map((e) => File.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreatePostWithFiles>, I>>(base?: I): CreatePostWithFiles {
    return CreatePostWithFiles.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreatePostWithFiles>, I>>(object: I): CreatePostWithFiles {
    const message = createBaseCreatePostWithFiles();
    message.fields = (object.fields !== undefined && object.fields !== null)
      ? CreatePostDetails.fromPartial(object.fields)
      : undefined;
    message.files = object.files?.map((e) => File.fromPartial(e)) || [];
    return message;
  },
};

function createBaseFile(): File {
  return { name: "", mimeType: "", length: 0n };
}

export const File = {
  encode(message: File, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.name !== "") {
      writer.uint32(10).string(message.name);
    }
    if (message.mimeType !== "") {
      writer.uint32(18).string(message.mimeType);
    }
    if (message.length !== 0n) {
      if (BigInt.asUintN(64, message.length) !== message.length) {
        throw new globalThis.Error("value provided for field message.length of type uint64 too large");
      }
      writer.uint32(24).uint64(message.length.toString());
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): File {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFile();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.name = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.mimeType = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.length = longToBigint(reader.uint64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): File {
    return {
      name: isSet(object.name) ? globalThis.String(object.name) : "",
      mimeType: isSet(object.mimeType) ? globalThis.String(object.mimeType) : "",
      length: isSet(object.length) ? BigInt(object.length) : 0n,
    };
  },

  toJSON(message: File): unknown {
    const obj: any = {};
    if (message.name !== "") {
      obj.name = message.name;
    }
    if (message.mimeType !== "") {
      obj.mimeType = message.mimeType;
    }
    if (message.length !== 0n) {
      obj.length = message.length.toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<File>, I>>(base?: I): File {
    return File.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<File>, I>>(object: I): File {
    const message = createBaseFile();
    message.name = object.name ?? "";
    message.mimeType = object.mimeType ?? "";
    message.length = object.length ?? 0n;
    return message;
  },
};

function createBaseGetPostsData(): GetPostsData {
  return { allowedPostTypes: 0, by: [], allPosts: false, cursor: new Uint8Array(0), tags: "" };
}

export const GetPostsData = {
  encode(message: GetPostsData, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.allowedPostTypes !== 0) {
      writer.uint32(8).uint32(message.allowedPostTypes);
    }
    for (const v of message.by) {
      writer.uint32(18).string(v!);
    }
    if (message.allPosts !== false) {
      writer.uint32(24).bool(message.allPosts);
    }
    if (message.cursor.length !== 0) {
      writer.uint32(34).bytes(message.cursor);
    }
    if (message.tags !== "") {
      writer.uint32(42).string(message.tags);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetPostsData {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetPostsData();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.allowedPostTypes = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.by.push(reader.string());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.allPosts = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.cursor = reader.bytes();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.tags = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): GetPostsData {
    return {
      allowedPostTypes: isSet(object.allowedPostTypes) ? globalThis.Number(object.allowedPostTypes) : 0,
      by: globalThis.Array.isArray(object?.by) ? object.by.map((e: any) => globalThis.String(e)) : [],
      allPosts: isSet(object.allPosts) ? globalThis.Boolean(object.allPosts) : false,
      cursor: isSet(object.cursor) ? bytesFromBase64(object.cursor) : new Uint8Array(0),
      tags: isSet(object.tags) ? globalThis.String(object.tags) : "",
    };
  },

  toJSON(message: GetPostsData): unknown {
    const obj: any = {};
    if (message.allowedPostTypes !== 0) {
      obj.allowedPostTypes = Math.round(message.allowedPostTypes);
    }
    if (message.by?.length) {
      obj.by = message.by;
    }
    if (message.allPosts !== false) {
      obj.allPosts = message.allPosts;
    }
    if (message.cursor.length !== 0) {
      obj.cursor = base64FromBytes(message.cursor);
    }
    if (message.tags !== "") {
      obj.tags = message.tags;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<GetPostsData>, I>>(base?: I): GetPostsData {
    return GetPostsData.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<GetPostsData>, I>>(object: I): GetPostsData {
    const message = createBaseGetPostsData();
    message.allowedPostTypes = object.allowedPostTypes ?? 0;
    message.by = object.by?.map((e) => e) || [];
    message.allPosts = object.allPosts ?? false;
    message.cursor = object.cursor ?? new Uint8Array(0);
    message.tags = object.tags ?? "";
    return message;
  },
};

function createBaseFullPosts(): FullPosts {
  return { posts: [] };
}

export const FullPosts = {
  encode(message: FullPosts, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.posts) {
      FullPost.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FullPosts {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFullPosts();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.posts.push(FullPost.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FullPosts {
    return { posts: globalThis.Array.isArray(object?.posts) ? object.posts.map((e: any) => FullPost.fromJSON(e)) : [] };
  },

  toJSON(message: FullPosts): unknown {
    const obj: any = {};
    if (message.posts?.length) {
      obj.posts = message.posts.map((e) => FullPost.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FullPosts>, I>>(base?: I): FullPosts {
    return FullPosts.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FullPosts>, I>>(object: I): FullPosts {
    const message = createBaseFullPosts();
    message.posts = object.posts?.map((e) => FullPost.fromPartial(e)) || [];
    return message;
  },
};

function createBaseVotes(): Votes {
  return { votes: [] };
}

export const Votes = {
  encode(message: Votes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.votes) {
      Vote.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Votes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVotes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.votes.push(Vote.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Votes {
    return { votes: globalThis.Array.isArray(object?.votes) ? object.votes.map((e: any) => Vote.fromJSON(e)) : [] };
  },

  toJSON(message: Votes): unknown {
    const obj: any = {};
    if (message.votes?.length) {
      obj.votes = message.votes.map((e) => Vote.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Votes>, I>>(base?: I): Votes {
    return Votes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Votes>, I>>(object: I): Votes {
    const message = createBaseVotes();
    message.votes = object.votes?.map((e) => Vote.fromPartial(e)) || [];
    return message;
  },
};

function createBaseVote(): Vote {
  return { id: new Uint8Array(0), val: undefined };
}

export const Vote = {
  encode(message: Vote, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.id.length !== 0) {
      writer.uint32(10).bytes(message.id);
    }
    if (message.val !== undefined) {
      BoolValue.encode(message.val, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Vote {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseVote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.id = reader.bytes();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.val = BoolValue.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Vote {
    return {
      id: isSet(object.id) ? bytesFromBase64(object.id) : new Uint8Array(0),
      val: isSet(object.val) ? BoolValue.fromJSON(object.val) : undefined,
    };
  },

  toJSON(message: Vote): unknown {
    const obj: any = {};
    if (message.id.length !== 0) {
      obj.id = base64FromBytes(message.id);
    }
    if (message.val !== undefined) {
      obj.val = BoolValue.toJSON(message.val);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Vote>, I>>(base?: I): Vote {
    return Vote.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Vote>, I>>(object: I): Vote {
    const message = createBaseVote();
    message.id = object.id ?? new Uint8Array(0);
    message.val = (object.val !== undefined && object.val !== null) ? BoolValue.fromPartial(object.val) : undefined;
    return message;
  },
};

function createBasePostIds(): PostIds {
  return { ids: [] };
}

export const PostIds = {
  encode(message: PostIds, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.ids) {
      writer.uint32(10).bytes(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PostIds {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePostIds();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.ids.push(reader.bytes());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PostIds {
    return { ids: globalThis.Array.isArray(object?.ids) ? object.ids.map((e: any) => bytesFromBase64(e)) : [] };
  },

  toJSON(message: PostIds): unknown {
    const obj: any = {};
    if (message.ids?.length) {
      obj.ids = message.ids.map((e) => base64FromBytes(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PostIds>, I>>(base?: I): PostIds {
    return PostIds.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PostIds>, I>>(object: I): PostIds {
    const message = createBasePostIds();
    message.ids = object.ids?.map((e) => e) || [];
    return message;
  },
};

function createBaseCreateFileFields(): CreateFileFields {
  return { expiry: 0n, by: "", files: [] };
}

export const CreateFileFields = {
  encode(message: CreateFileFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.expiry !== 0n) {
      if (BigInt.asUintN(64, message.expiry) !== message.expiry) {
        throw new globalThis.Error("value provided for field message.expiry of type uint64 too large");
      }
      writer.uint32(8).uint64(message.expiry.toString());
    }
    if (message.by !== "") {
      writer.uint32(18).string(message.by);
    }
    for (const v of message.files) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateFileFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateFileFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.expiry = longToBigint(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.by = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.files.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreateFileFields {
    return {
      expiry: isSet(object.expiry) ? BigInt(object.expiry) : 0n,
      by: isSet(object.by) ? globalThis.String(object.by) : "",
      files: globalThis.Array.isArray(object?.files) ? object.files.map((e: any) => globalThis.String(e)) : [],
    };
  },

  toJSON(message: CreateFileFields): unknown {
    const obj: any = {};
    if (message.expiry !== 0n) {
      obj.expiry = message.expiry.toString();
    }
    if (message.by !== "") {
      obj.by = message.by;
    }
    if (message.files?.length) {
      obj.files = message.files;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreateFileFields>, I>>(base?: I): CreateFileFields {
    return CreateFileFields.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreateFileFields>, I>>(object: I): CreateFileFields {
    const message = createBaseCreateFileFields();
    message.expiry = object.expiry ?? 0n;
    message.by = object.by ?? "";
    message.files = object.files?.map((e) => e) || [];
    return message;
  },
};

function createBasePollVote(): PollVote {
  return { postId: new Uint8Array(0), optionId: 0 };
}

export const PollVote = {
  encode(message: PollVote, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.postId.length !== 0) {
      writer.uint32(10).bytes(message.postId);
    }
    if (message.optionId !== 0) {
      writer.uint32(16).int32(message.optionId);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollVote {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollVote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.postId = reader.bytes();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.optionId = reader.int32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollVote {
    return {
      postId: isSet(object.postId) ? bytesFromBase64(object.postId) : new Uint8Array(0),
      optionId: isSet(object.optionId) ? globalThis.Number(object.optionId) : 0,
    };
  },

  toJSON(message: PollVote): unknown {
    const obj: any = {};
    if (message.postId.length !== 0) {
      obj.postId = base64FromBytes(message.postId);
    }
    if (message.optionId !== 0) {
      obj.optionId = Math.round(message.optionId);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollVote>, I>>(base?: I): PollVote {
    return PollVote.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollVote>, I>>(object: I): PollVote {
    const message = createBasePollVote();
    message.postId = object.postId ?? new Uint8Array(0);
    message.optionId = object.optionId ?? 0;
    return message;
  },
};

function createBasePollOption(): PollOption {
  return { optionId: 0, votes: 0n };
}

export const PollOption = {
  encode(message: PollOption, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.optionId !== 0) {
      writer.uint32(8).int32(message.optionId);
    }
    if (message.votes !== 0n) {
      if (BigInt.asIntN(64, message.votes) !== message.votes) {
        throw new globalThis.Error("value provided for field message.votes of type int64 too large");
      }
      writer.uint32(16).int64(message.votes.toString());
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollOption {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollOption();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.optionId = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.votes = longToBigint(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollOption {
    return {
      optionId: isSet(object.optionId) ? globalThis.Number(object.optionId) : 0,
      votes: isSet(object.votes) ? BigInt(object.votes) : 0n,
    };
  },

  toJSON(message: PollOption): unknown {
    const obj: any = {};
    if (message.optionId !== 0) {
      obj.optionId = Math.round(message.optionId);
    }
    if (message.votes !== 0n) {
      obj.votes = message.votes.toString();
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollOption>, I>>(base?: I): PollOption {
    return PollOption.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollOption>, I>>(object: I): PollOption {
    const message = createBasePollOption();
    message.optionId = object.optionId ?? 0;
    message.votes = object.votes ?? 0n;
    return message;
  },
};

function createBasePollResult(): PollResult {
  return { pollId: new Uint8Array(0), values: [] };
}

export const PollResult = {
  encode(message: PollResult, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pollId.length !== 0) {
      writer.uint32(10).bytes(message.pollId);
    }
    writer.uint32(18).fork();
    for (const v of message.values) {
      writer.int32(v);
    }
    writer.ldelim();
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollResult {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollResult();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pollId = reader.bytes();
          continue;
        case 2:
          if (tag === 16) {
            message.values.push(reader.int32());

            continue;
          }

          if (tag === 18) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.values.push(reader.int32());
            }

            continue;
          }

          break;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollResult {
    return {
      pollId: isSet(object.pollId) ? bytesFromBase64(object.pollId) : new Uint8Array(0),
      values: globalThis.Array.isArray(object?.values) ? object.values.map((e: any) => globalThis.Number(e)) : [],
    };
  },

  toJSON(message: PollResult): unknown {
    const obj: any = {};
    if (message.pollId.length !== 0) {
      obj.pollId = base64FromBytes(message.pollId);
    }
    if (message.values?.length) {
      obj.values = message.values.map((e) => Math.round(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollResult>, I>>(base?: I): PollResult {
    return PollResult.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollResult>, I>>(object: I): PollResult {
    const message = createBasePollResult();
    message.pollId = object.pollId ?? new Uint8Array(0);
    message.values = object.values?.map((e) => e) || [];
    return message;
  },
};

function createBasePollResults(): PollResults {
  return { results: [] };
}

export const PollResults = {
  encode(message: PollResults, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.results) {
      PollResult.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollResults {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollResults();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.results.push(PollResult.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollResults {
    return {
      results: globalThis.Array.isArray(object?.results) ? object.results.map((e: any) => PollResult.fromJSON(e)) : [],
    };
  },

  toJSON(message: PollResults): unknown {
    const obj: any = {};
    if (message.results?.length) {
      obj.results = message.results.map((e) => PollResult.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollResults>, I>>(base?: I): PollResults {
    return PollResults.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollResults>, I>>(object: I): PollResults {
    const message = createBasePollResults();
    message.results = object.results?.map((e) => PollResult.fromPartial(e)) || [];
    return message;
  },
};

function createBaseCreatePollFields(): CreatePollFields {
  return { postId: new Uint8Array(0), numOfOpts: 0 };
}

export const CreatePollFields = {
  encode(message: CreatePollFields, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.postId.length !== 0) {
      writer.uint32(10).bytes(message.postId);
    }
    if (message.numOfOpts !== 0) {
      writer.uint32(16).uint32(message.numOfOpts);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreatePollFields {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreatePollFields();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.postId = reader.bytes();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.numOfOpts = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): CreatePollFields {
    return {
      postId: isSet(object.postId) ? bytesFromBase64(object.postId) : new Uint8Array(0),
      numOfOpts: isSet(object.numOfOpts) ? globalThis.Number(object.numOfOpts) : 0,
    };
  },

  toJSON(message: CreatePollFields): unknown {
    const obj: any = {};
    if (message.postId.length !== 0) {
      obj.postId = base64FromBytes(message.postId);
    }
    if (message.numOfOpts !== 0) {
      obj.numOfOpts = Math.round(message.numOfOpts);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<CreatePollFields>, I>>(base?: I): CreatePollFields {
    return CreatePollFields.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<CreatePollFields>, I>>(object: I): CreatePollFields {
    const message = createBaseCreatePollFields();
    message.postId = object.postId ?? new Uint8Array(0);
    message.numOfOpts = object.numOfOpts ?? 0;
    return message;
  },
};

function createBasePollUserVote(): PollUserVote {
  return { pollId: new Uint8Array(0), val: 0 };
}

export const PollUserVote = {
  encode(message: PollUserVote, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pollId.length !== 0) {
      writer.uint32(10).bytes(message.pollId);
    }
    if (message.val !== 0) {
      writer.uint32(16).uint32(message.val);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollUserVote {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollUserVote();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pollId = reader.bytes();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.val = reader.uint32();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollUserVote {
    return {
      pollId: isSet(object.pollId) ? bytesFromBase64(object.pollId) : new Uint8Array(0),
      val: isSet(object.val) ? globalThis.Number(object.val) : 0,
    };
  },

  toJSON(message: PollUserVote): unknown {
    const obj: any = {};
    if (message.pollId.length !== 0) {
      obj.pollId = base64FromBytes(message.pollId);
    }
    if (message.val !== 0) {
      obj.val = Math.round(message.val);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollUserVote>, I>>(base?: I): PollUserVote {
    return PollUserVote.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollUserVote>, I>>(object: I): PollUserVote {
    const message = createBasePollUserVote();
    message.pollId = object.pollId ?? new Uint8Array(0);
    message.val = object.val ?? 0;
    return message;
  },
};

function createBasePollUserVotes(): PollUserVotes {
  return { votes: [] };
}

export const PollUserVotes = {
  encode(message: PollUserVotes, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.votes) {
      PollUserVote.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PollUserVotes {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePollUserVotes();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.votes.push(PollUserVote.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PollUserVotes {
    return {
      votes: globalThis.Array.isArray(object?.votes) ? object.votes.map((e: any) => PollUserVote.fromJSON(e)) : [],
    };
  },

  toJSON(message: PollUserVotes): unknown {
    const obj: any = {};
    if (message.votes?.length) {
      obj.votes = message.votes.map((e) => PollUserVote.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PollUserVotes>, I>>(base?: I): PollUserVotes {
    return PollUserVotes.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PollUserVotes>, I>>(object: I): PollUserVotes {
    const message = createBasePollUserVotes();
    message.votes = object.votes?.map((e) => PollUserVote.fromPartial(e)) || [];
    return message;
  },
};

function createBasePostReport(): PostReport {
  return { postId: new Uint8Array(0), sevirity: 0, description: "" };
}

export const PostReport = {
  encode(message: PostReport, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.postId.length !== 0) {
      writer.uint32(10).bytes(message.postId);
    }
    if (message.sevirity !== 0) {
      writer.uint32(16).int32(message.sevirity);
    }
    if (message.description !== "") {
      writer.uint32(26).string(message.description);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PostReport {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePostReport();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.postId = reader.bytes();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.sevirity = reader.int32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.description = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PostReport {
    return {
      postId: isSet(object.postId) ? bytesFromBase64(object.postId) : new Uint8Array(0),
      sevirity: isSet(object.sevirity) ? globalThis.Number(object.sevirity) : 0,
      description: isSet(object.description) ? globalThis.String(object.description) : "",
    };
  },

  toJSON(message: PostReport): unknown {
    const obj: any = {};
    if (message.postId.length !== 0) {
      obj.postId = base64FromBytes(message.postId);
    }
    if (message.sevirity !== 0) {
      obj.sevirity = Math.round(message.sevirity);
    }
    if (message.description !== "") {
      obj.description = message.description;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PostReport>, I>>(base?: I): PostReport {
    return PostReport.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PostReport>, I>>(object: I): PostReport {
    const message = createBasePostReport();
    message.postId = object.postId ?? new Uint8Array(0);
    message.sevirity = object.sevirity ?? 0;
    message.description = object.description ?? "";
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  const bin = globalThis.atob(b64);
  const arr = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; ++i) {
    arr[i] = bin.charCodeAt(i);
  }
  return arr;
}

function base64FromBytes(arr: Uint8Array): string {
  const bin: string[] = [];
  arr.forEach((byte) => {
    bin.push(globalThis.String.fromCharCode(byte));
  });
  return globalThis.btoa(bin.join(""));
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | bigint | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToBigint(long: Long) {
  return BigInt(long.toString());
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
