

export class Slides extends HTMLElement {

    private activeIndex = 0;

    constructor(slides: Slide[]) {
        super();
        // this.append(...slides);
        // this.setupSlideClasses();
        this.addSlides(slides)
        this.addEventListener("click", this.swipeEvent);
    }

    public addSlides(slides: Slide[]) {
        this.append(...slides);
        this.setupSlideClasses();
    }

    private getIndex(i: number, length = this.childElementCount) {
        return (i + length) % length;
    }

    private static classNames = ["prev", "active", "next", ""];

    private setupSlideClasses() {
        for (var child of this.children) {
            child.removeAttribute("class");
        }
        for (var i = -1; i < 2; i++) {
            this.children[this.getIndex(i + this.activeIndex)].className = Slides.classNames[i + 1];
        }
    }

    private swipeEvent(e: MouseEvent) {
        e.preventDefault();

        const slides = this.children;
        const { left, width } = this.getBoundingClientRect();
        const relX = (e.clientX - left) / width

        const [a, newIndex] = (slides.length == 2 || relX > 0.5) ? [-1, this.getIndex(this.activeIndex + 1)] : [-2, this.getIndex(this.activeIndex - 1)];
        
        for (var j = a; j < 4 + a; j++) {
            slides[this.getIndex(this.activeIndex + j)].className = Slides.classNames[this.getIndex(j + ((a + 1) * a), Slides.classNames.length)];
        }
        this.activeIndex = newIndex;
    }
}


export class Slide extends HTMLElement {
    constructor(innerHtml: string | HTMLElement) {
        super();
        if (typeof innerHtml === "string") { this.innerHTML = innerHtml; }
        else { this.append(innerHtml) }
    }
}

customElements.define("sli-des", Slides);
customElements.define("sli-de", Slide);