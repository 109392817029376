export const MAX_TOTAL_FILES_SIZE = 96_000_000;
export const DEFAULT_USER_ICON = "/assets/default-user.webp";
export const API_URL = process.env.JS_ENV_API_URL!;
export const API_CDN_URL = process.env.JS_ENV_API_CDN_URL!;
export const CDN_STORAGE = process.env.JS_ENV_CDN_STORAGE!;
export const CREATE_USER_FUNC_URL = process.env.JS_ENV_CREATE_USER_URL!;
export const CREATE_USER_CHAT_FUNC_URL =
  process.env.JS_ENV_CREATE_USER_CHAT_URL!;
export const FIRESTORE_BASE_URL = process.env.JS_ENV_FB_FIRESTORE_URL!;
// export const FIRERTDB_BASE_URL = process.env.JS_ENV_FB_FIRERTDB_URL!;

export const MAX_PFP_CONTENT_SIZE = Number(
  process.env.JS_ENV_PFP_SIZE ?? 1 * 1024 * 1024,
);

export const MOBILE_MAX_WIDTH_PX = 880;

console.log({
  MAX_TOTAL_FILES_SIZE,
  DEFAULT_USER_ICON,
  API_URL,
  API_CDN_URL,
  CDN_STORAGE,
  CREATE_USER_FUNC_URL,
  FIRESTORE_BASE_URL,
  MOBILE_MAX_WIDTH_PX,
  CREATE_USER_CHAT_FUNC_URL,
  version: process.env.LUPYD_VERSION,
});
