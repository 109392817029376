import { Utils } from "../bin/utils";
import { LupydHomePage } from "./homePage";
import { RouteBuilder, routeNavigator } from "./navigator";

export const rootRouteBuilder: RouteBuilder = async (url: string) => {
    const parsedUrl = new URL(url)
    if (parsedUrl.pathname !== "/") {
        return undefined
    }

    // await Utils.delay(100);
    // const div = document.createElement("div")
    // div.innerText = "Index"
    // const authBtn = document.createElement("button")
    // authBtn.innerText = "Go Auth"
    // authBtn.addEventListener("click", () => {
    //     routeNavigator.pushRoute("/auth");
    // })

    // const counterBtn = document.createElement("button")
    // counterBtn.innerText = "add"
    // const counter = document.createElement("div")
    // counter.innerText = '0'
    // counterBtn.addEventListener("click", _ => counter.innerText = `${Number(counter.innerText) + 1}`)
    // div.append(authBtn, counter, counterBtn)
    // return div
    return new LupydHomePage()
}

export const authRouteBuilder: RouteBuilder = async (url: string) => {
    const parsedUrl = new URL(url)
    if (parsedUrl.pathname !== "/auth") {
        return undefined
    }
    await Utils.delay(200);
    const div = document.createElement("div")
    div.innerText = "Auth"
    const homeBtn = document.createElement("button")
    homeBtn.innerText = "Go Home"
    homeBtn.addEventListener("click", () => {
        routeNavigator.pushRoute("/");
    })
    div.append(homeBtn)
    return div
}

