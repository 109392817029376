import { FetchType, getPosts } from "../api/post";
import { ulidStringify, Utils } from "../bin/utils";
import { postElement, PostSkeleton } from "./post-div";
import van from "vanjs-core";
import { FullPost } from "../protos/post";
import { getFollowedUsersState } from "../doc/user";
import * as vanX from "vanjs-ext";
import store from "store2";

const { ul, div, button, li } = van.tags;

const MIN_INTERVAL_PAGINATION_IN_MS = 10_000;

export class PostFeed extends HTMLElement {
  maxId?: Uint8Array;
  minId?: Uint8Array;
  lastFetchTime?: number;

  listView() {
    return this.querySelector("ul")!;
  }

  constructor() {
    super();

    this.replaceChildren(
      div(
        { class: "post-feed" },
        vanX.list(ul({ class: "no-pad no-list-style" }), this.posts, (post) =>
          li(postElement(post.val, true)),
        ),
        div(
          {
            class: "load-more-wrapper",
          },
          button(
            {
              class: "theme-button",
              onclick: () => {
                this.paginate();
              },
            },
            "load more posts...",
          ),
        ),
      ),
    );
    this.paginate();
    this.paginateWithFollowedUsers();
  }

  async paginate() {
    console.log({
      lastFetchTime: this.lastFetchTime,
      MIN_INTERVAL_PAGINATION_IN_MS,
      now: Date.now(),
    });
    if (
      this.lastFetchTime &&
      Date.now() - this.lastFetchTime < MIN_INTERVAL_PAGINATION_IN_MS
    ) {
      Utils.showSnackBar("Too Many Requests, Wait some time");
      return;
    }

    const btn = this.querySelector(".load-more-wrapper")!;
    // btn.replaceChildren(div({ class: "loader" }));

    btn.replaceChildren(div(...this.getPostSkeletons(10)));

    // {
    //   for (let i = 0; i < 1000; i++) {
    //     console.warn("DEBUG ONLY, Remove this section");
    //     await Utils.delay(1000);
    //   }
    // }

    try {
      this.lastFetchTime = Date.now();
      const posts = await getPosts({
        fetchType: FetchType.Latest,
        start: this.minId ? ulidStringify(this.minId!) : undefined,
        allowedPostTypes: Number(store.get("allowedPostTypes") ?? "1"),
      });

      this.posts.push(...posts.map(vanX.noreactive));
      if (posts.length !== 0) {
        const sortedPosts = Array.from(posts).sort((a, b) =>
          a.id > b.id ? 1 : -1,
        );
        this.minId = sortedPosts[0].id;
        this.maxId = sortedPosts[sortedPosts.length - 1].id;
      }
    } catch (err) {
      console.error(err);
    } finally {
      btn.replaceChildren(
        button(
          { class: "theme-button", onclick: () => this.paginate() },
          "load more posts...",
        ),
      );
    }
  }

  async paginateWithFollowedUsers() {
    const followedUsers = getFollowedUsersState().val;
    if (followedUsers.length !== 0) {
      const usersPosts = await getPosts({
        fetchType: FetchType.Users,
        fetchTypeFields: followedUsers,
      });

      this.posts.push(...usersPosts.map(vanX.noreactive));
    }
  }

  private posts = vanX.reactive([] as FullPost[]);

  addPost(post: FullPost) {
    this.posts.push(vanX.noreactive(post));
  }

  getPostSkeletons(length: number) {
    return Array.from({ length }, (_, __) => PostSkeleton());
  }
}

customElements.define("post-feed", PostFeed);
