// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.0
//   protoc               v5.27.3
// source: chats.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";
import Long = require("long");

export const protobufPackage = "lupyd.chat";

export interface ChatSession {
  ts: bigint;
  version: number;
  user1: string;
  user2: string;
}

export interface ChatSessions {
  sessions: ChatSession[];
}

export interface ChatKeys {
  keys: Uint8Array[];
}

export interface ChatKey {
  key: Uint8Array;
}

function createBaseChatSession(): ChatSession {
  return { ts: 0n, version: 0, user1: "", user2: "" };
}

export const ChatSession = {
  encode(message: ChatSession, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.ts !== 0n) {
      if (BigInt.asUintN(64, message.ts) !== message.ts) {
        throw new globalThis.Error("value provided for field message.ts of type uint64 too large");
      }
      writer.uint32(8).uint64(message.ts.toString());
    }
    if (message.version !== 0) {
      writer.uint32(16).uint32(message.version);
    }
    if (message.user1 !== "") {
      writer.uint32(26).string(message.user1);
    }
    if (message.user2 !== "") {
      writer.uint32(34).string(message.user2);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChatSession {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChatSession();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.ts = longToBigint(reader.uint64() as Long);
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.version = reader.uint32();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.user1 = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.user2 = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChatSession {
    return {
      ts: isSet(object.ts) ? BigInt(object.ts) : 0n,
      version: isSet(object.version) ? globalThis.Number(object.version) : 0,
      user1: isSet(object.user1) ? globalThis.String(object.user1) : "",
      user2: isSet(object.user2) ? globalThis.String(object.user2) : "",
    };
  },

  toJSON(message: ChatSession): unknown {
    const obj: any = {};
    if (message.ts !== 0n) {
      obj.ts = message.ts.toString();
    }
    if (message.version !== 0) {
      obj.version = Math.round(message.version);
    }
    if (message.user1 !== "") {
      obj.user1 = message.user1;
    }
    if (message.user2 !== "") {
      obj.user2 = message.user2;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatSession>, I>>(base?: I): ChatSession {
    return ChatSession.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatSession>, I>>(object: I): ChatSession {
    const message = createBaseChatSession();
    message.ts = object.ts ?? 0n;
    message.version = object.version ?? 0;
    message.user1 = object.user1 ?? "";
    message.user2 = object.user2 ?? "";
    return message;
  },
};

function createBaseChatSessions(): ChatSessions {
  return { sessions: [] };
}

export const ChatSessions = {
  encode(message: ChatSessions, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.sessions) {
      ChatSession.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChatSessions {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChatSessions();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.sessions.push(ChatSession.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChatSessions {
    return {
      sessions: globalThis.Array.isArray(object?.sessions)
        ? object.sessions.map((e: any) => ChatSession.fromJSON(e))
        : [],
    };
  },

  toJSON(message: ChatSessions): unknown {
    const obj: any = {};
    if (message.sessions?.length) {
      obj.sessions = message.sessions.map((e) => ChatSession.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatSessions>, I>>(base?: I): ChatSessions {
    return ChatSessions.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatSessions>, I>>(object: I): ChatSessions {
    const message = createBaseChatSessions();
    message.sessions = object.sessions?.map((e) => ChatSession.fromPartial(e)) || [];
    return message;
  },
};

function createBaseChatKeys(): ChatKeys {
  return { keys: [] };
}

export const ChatKeys = {
  encode(message: ChatKeys, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.keys) {
      writer.uint32(10).bytes(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChatKeys {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChatKeys();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.keys.push(reader.bytes());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChatKeys {
    return { keys: globalThis.Array.isArray(object?.keys) ? object.keys.map((e: any) => bytesFromBase64(e)) : [] };
  },

  toJSON(message: ChatKeys): unknown {
    const obj: any = {};
    if (message.keys?.length) {
      obj.keys = message.keys.map((e) => base64FromBytes(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatKeys>, I>>(base?: I): ChatKeys {
    return ChatKeys.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatKeys>, I>>(object: I): ChatKeys {
    const message = createBaseChatKeys();
    message.keys = object.keys?.map((e) => e) || [];
    return message;
  },
};

function createBaseChatKey(): ChatKey {
  return { key: new Uint8Array(0) };
}

export const ChatKey = {
  encode(message: ChatKey, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.key.length !== 0) {
      writer.uint32(10).bytes(message.key);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ChatKey {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseChatKey();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.key = reader.bytes();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): ChatKey {
    return { key: isSet(object.key) ? bytesFromBase64(object.key) : new Uint8Array(0) };
  },

  toJSON(message: ChatKey): unknown {
    const obj: any = {};
    if (message.key.length !== 0) {
      obj.key = base64FromBytes(message.key);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<ChatKey>, I>>(base?: I): ChatKey {
    return ChatKey.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<ChatKey>, I>>(object: I): ChatKey {
    const message = createBaseChatKey();
    message.key = object.key ?? new Uint8Array(0);
    return message;
  },
};

function bytesFromBase64(b64: string): Uint8Array {
  const bin = globalThis.atob(b64);
  const arr = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; ++i) {
    arr[i] = bin.charCodeAt(i);
  }
  return arr;
}

function base64FromBytes(arr: Uint8Array): string {
  const bin: string[] = [];
  arr.forEach((byte) => {
    bin.push(globalThis.String.fromCharCode(byte));
  });
  return globalThis.btoa(bin.join(""));
}

type Builtin = Date | Function | Uint8Array | string | number | boolean | bigint | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToBigint(long: Long) {
  return BigInt(long.toString());
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
