import { Ulid } from "id128";
import { isDarkMode, isMobileResolution, UiIcon, Utils } from "../../bin/utils";
import { AuthHandler } from "../../firebase/auth";
import { routeNavigator } from "../../routes/navigator";
import { LupydDatabasesElement, SEARCH_DB_STORE_NAME } from "../databases";
import { MyProfileButton } from "./profile-button";
import van, { State } from "vanjs-core";
import { Await } from "vanjs-ui";
import { FullPost } from "../../protos/post";

const { li, ul, span, a, h1, input, button, div, img } = van.tags;

export const LupydIconImage = () => {
  return img({
    src: () => (isDarkMode.val ? "/favicon-dark.svg" : "/favicon.svg"),
    alt: "lupyd icon",
  });
};

export const LupydHeader = (
  searchText: State<string> = van.state(""),
  onSearchFieldSubmit: (s: State<string>) => void = (s) =>
    routeNavigator.pushRoute(encodeURI(`/search?q=${s.val}`)),
  showChats?: State<boolean>,
  onCreatePostCallback?: (post: FullPost) => void,
) => {
  const title = a(
    {
      class: "no-text-decoration",
      href: "/",
      title: "Lupyd Home",
      onclick(e: Event) {
        e.preventDefault();
        routeNavigator.pushRoute("/");
        console.log("Refreshed");
      },
    },
    () => (isMobileResolution.val ? LupydIconImage() : h1("Lupyd")),
  );

  const searchField = SearchField(searchText, onSearchFieldSubmit);

  return div(
    { class: "lupyd-header" },
    title,
    searchField,
    showChats
      ? button(
          {
            class: "no-button-decoration create-post-button",
            onclick() {
              showChats.val = !showChats.val;
            },
          },
          UiIcon("message-circle-more"),
        )
      : undefined,
    createPostButton(onCreatePostCallback),
    new MyProfileButton(),
  );
};

function createPostButton(
  onCreateCallback: (post: FullPost) => void = (_) => {},
) {
  const href = "/create-post";

  const title = "Create Post";
  return a(
    {
      href,
      class: "create-post-button no-text-decoration",
      title,
      ariaLabel: title,
      onclick(e: Event) {
        e.preventDefault();

        AuthHandler.getUsername().then((username) => {
          if (username) {
            routeNavigator.pushRoute(href, {
              onCreateCallback: onCreateCallback,
            });
          } else {
            Utils.showSnackBar(
              "User should be signed in to perform this action",
            );
          }
        });
      },
    },
    span({ class: "create-post-icon" }, UiIcon("plus")),
  );
}

const saveSearchedText = async (query: string) => {
  const db = (document.querySelector(
    "lupyd-databases",
  ) as LupydDatabasesElement)!.localDb;
  if (db) {
    await db.put(SEARCH_DB_STORE_NAME, query, Ulid.generate().toCanonical());
  }
};

const getRecentlySearchedQueries = async (maxValues: number) => {
  const db = (document.querySelector(
    "lupyd-databases",
  ) as LupydDatabasesElement)!.localDb;
  let queries = [] as string[];

  if (!db) {
    return queries;
  }
  const tx = db.transaction(SEARCH_DB_STORE_NAME);
  const cursor = await tx.store.openCursor(null, "prev");
  if (cursor) {
    for await (const row of cursor) {
      if (typeof row.value === "string") {
        queries.push(row.value);
        if (queries.length > maxValues) {
          break;
        }
      }
    }
  }

  await tx.done;

  return queries;
};

export const SearchField = (
  searchText: State<string>,
  onSubmit: (s: State<string>) => void,
) => {
  Await({ value: getRecentlySearchedQueries(15) }, (queries) =>
    div(() => ul(...fuzzySearch(searchText.val, queries).map(li))),
  );

  const clearButtonVisible = van.derive(() => searchText.val.length !== 0);
  const textField = input({
    class: "lupyd-search-field",
    ariaLabel: "search field input",
    type: "text",
    value: searchText,
    oninput() {
      searchText.val = (this as HTMLInputElement).value;
    },
    onkeyup(e: KeyboardEvent) {
      if (e.key === "Enter") {
        (this as HTMLInputElement).blur();
        onSubmit(searchText);
        saveSearchedText(searchText.val);
      }
    },
  });
  const clearBtn = button(
    {
      class: "no-button-decoration",
      ariaLabel: "clear search field",
      title: "Clear",
      onclick: () => (searchText.val = ""),
      style: () => `display: ${clearButtonVisible.val ? "block" : "none"}`,
    },

    UiIcon("x"),
    // span({ class: `material-symbols-outlined` }, "clear"),
  );

  const title = "search";
  const searchBtn = button(
    {
      class: "no-button-decoration",
      title,
      ariaLabel: title,
      onclick() {
        (this as HTMLButtonElement).blur();
        onSubmit(searchText);
        saveSearchedText(searchText.val);
      },
    },
    UiIcon(title),
  );

  return div({ class: "search-field" }, textField, clearBtn, searchBtn);
};

function fuzzySearch(query: string, list: Array<string>) {
  query = query.toLowerCase();
  const results = [];

  for (const item of list) {
    if (isFuzzyMatch(query, item.toLowerCase())) {
      results.push(item);
    }
  }

  return results;
}

function isFuzzyMatch(query: string, target: string) {
  let queryIndex = 0;
  let targetIndex = 0;

  while (queryIndex < query.length && targetIndex < target.length) {
    if (query[queryIndex] === target[targetIndex]) {
      queryIndex++;
    }
    targetIndex++;
  }

  return queryIndex === query.length;
}
