
import { ElementType, Elements, HyperElementType, Element as MElement, PrimitiveElement } from '../protos/lupyd-md'

export class LupydMarkdownElements extends HTMLElement {
  elements: Elements
  constructor(elements: Elements) {
    super()
    this.elements = elements
  }


  connectedCallback() {
    this.render()
  }


  render() {
    this.replaceChildren(...this.elements.elements.map(mElementToHTMLElement))
  }
}


function mElementToHTMLElement(element: MElement): HTMLElement {
  if (element.primitiveElement) {
    return convertPrimitiveToHTMLElement(element.primitiveElement, defaultWrapToHtmlElement)

  } else if (element.fileElement) {
    const mimeType = element.fileElement.mimeType
    if (mimeType.startsWith("image/")) {
      const image = document.createElement("img")
      image.src = element.fileElement.url
      return image
    } else if (mimeType.startsWith("video/")) {
      const video = document.createElement("video")
      video.src = element.fileElement.url
      return video
    } else {
      const anchor = document.createElement("a")

      anchor.href = element.fileElement.url
      anchor.addEventListener("click", _ => {
        console.warn("You are clicking on an unverified link")
        _.preventDefault()
      })


      anchor.replaceChildren(wrapTag("b", element.fileElement.filename))

      return anchor
    }

  } else if (element.hyperElement) {
    switch (element.hyperElement.tag) {
      case HyperElementType.Mention:
        {
          const anchor = document.createElement("a")
          anchor.href = `${window.location.origin}/@${element.hyperElement.body}`
          anchor.addEventListener("click", _ => {
            console.warn("You are clicking on an unverified link")
            _.preventDefault()
          })
          return anchor
        }
      case HyperElementType.HashTag:
        {
          return wrapTag("b", `#${element.hyperElement.body}`)
        }
      case HyperElementType.Post:
        {
          const anchor = document.createElement("a")
          anchor.href = `${window.location.origin}/post/${element.hyperElement.body}`
          anchor.addEventListener("click", _ => {
            console.warn("You are clicking on an unverified link")
            _.preventDefault()
          })
          return anchor
        }
      case HyperElementType.Group:
        {
          const anchor = document.createElement("a")
          anchor.href = `${window.location.origin}/group/${element.hyperElement.body}`
          anchor.addEventListener("click", _ => {
            console.warn("You are clicking on an unverified link")
            _.preventDefault()
          })
          return anchor
        }
      case HyperElementType.Link:
        {
          const anchor = document.createElement("a")
          anchor.href = element.hyperElement.body
          anchor.addEventListener("click", _ => {
            console.warn("You are clicking on an unverified link")
            _.preventDefault()
          })
          return anchor
        }
      case HyperElementType.UNRECOGNIZED: {

        const div = document.createElement("div")

        div.replaceChildren(
          wrapTag("b", element.hyperElement.tag.toString()),
          wrapTag("span", element.hyperElement.body))
        return div
      }
    }

  } else if (element.hyperCustomElement) {
    const div = document.createElement("div")

    div.replaceChildren(
      wrapTag("b", element.hyperCustomElement.tag),
      wrapTag("span", element.hyperCustomElement.body))
    return div
  } else {
    return wrapTag("div", '')
  }
}

function wrapTag(tagName: string, child: string | HTMLElement, className?: string) {
  const p = document.createElement(tagName)
  if (typeof child === "string") {
    p.innerText = child
  } else {
    p.append(child)
  }

  if (className)
    p.classList.add(className)

  return p
}


export function iterateTypes(type: ElementType) {
  const types: ElementType[] = []
  let checkType = ElementType.Spoiler
  while (checkType) {
    if ((type & checkType) == checkType) {
      types.push(checkType)
    }
    checkType = checkType >> 1
  }

  return types
}




export type WrapToHtmlElementFunction = (_: string | HTMLElement, __: ElementType) => HTMLElement
function convertPrimitiveToHTMLElement(element: PrimitiveElement, wrapToHtmlElement: WrapToHtmlElementFunction): HTMLElement {
  const type = element.elementType
  const text = element.text




  let child: HTMLElement | string = text
  for (const _type of iterateTypes(type)) {
    child = wrapToHtmlElement(child, _type)
  }

  if (typeof child === "string") {
    return wrapTag("span", child)
  } else {
    return child
  }
}

export function defaultWrapToHtmlElement(child: string | HTMLElement, type: ElementType) {
  switch (type) {
    case ElementType.Bold: return wrapTag("b", child)
    case ElementType.Normal: return wrapTag("span", child)
    case ElementType.Italic: return wrapTag("i", child)
    case ElementType.Header: return wrapTag("h1", child)
    case ElementType.UnderLine: return wrapTag("u", child)
    case ElementType.Code: return wrapTag("tt", child)
    case ElementType.Quote: return wrapTag("b", child, "quote")
    case ElementType.Spoiler: return wrapTag("span", child, "spoiler")
    case ElementType.UNRECOGNIZED: return wrapTag("span", child)
  }
}



customElements.define("lupyd-markdown-elements", LupydMarkdownElements)

