import { HTMLAttributes, UiIcon } from "../bin/utils";
import { AuthHandler } from "../firebase/auth";
import { routeNavigator } from "../routes/navigator";
import { UserProfileElement } from "../routes/userPage";
import { Button, LinkButton } from "./button";
import van, { State } from "vanjs-core";

const { div, b } = van.tags;

export const RootSideBar = (showChats: State<boolean>) => {
  const currentUsername = AuthHandler.currentUsername();

  const attr: HTMLAttributes = {
    class: "row center spread",
    style: "height: 3em",
  };

  const focusSearchField = () =>
    (
      routeNavigator
        .getCurrentPage()
        .querySelector(".lupyd-search-field")! as HTMLInputElement
    ).focus();

  const toggleChats = () => {
    showChats.val = !showChats.val;
  };

  const children = [
    Button(div(attr, b("Create Post"), UiIcon("plus")), () =>
      routeNavigator.pushRoute("/create-post"),
    ),
    Button(div(attr, b("Search"), UiIcon("search")), focusSearchField),
    Button(div(attr, b("Find People"), UiIcon("users")), focusSearchField),
    Button(div(attr, b("Chats"), UiIcon("message-circle-more")), toggleChats),

    Button(
      div(attr, b("Profile"), () =>
        UserProfileElement(currentUsername.val ?? ""),
      ),
      () => {
        if (currentUsername.val) {
          routeNavigator.pushRoute(`/user/${currentUsername.val}`);
        } else {
          routeNavigator.pushRoute(`/signin`);
        }
      },
    ),
    Button(div(attr, b("Settings"), UiIcon("settings")), () =>
      routeNavigator.pushRoute("/settings"),
    ),
    LinkButton(
      div(attr, b("Terms of Service"), UiIcon("scroll-text")),
      "/terms-of-service",
    ),
    LinkButton(
      div(attr, b("Privacy Policy"), UiIcon("scale")),
      "/privacy-policy",
    ),
  ];

  return div({ class: "lupyd-side-bar" }, ...children);
};
