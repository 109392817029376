import van from "vanjs-core";
import { ChatMessage, ChatSession } from "./default";
import { AuthHandler } from "../firebase/auth";
import * as vanX from "vanjs-ext";
import { UserProfileElement } from "../routes/userPage";
import { Await } from "vanjs-ui";
import { UiIcon, Utils } from "../bin/utils";
import { routeNavigator } from "../routes/navigator";
import { deleteChat } from "../api/chat";

const { ul, li, b, input, button, div, small, a } = van.tags;

const messageElement = (msg: ChatMessage, sender: string) => {
  const el = div(
    {
      class: `chat-message ${sender == msg.by ? "sent" : "recv"}`,
    },
    div(msg.msg),
    div({ class: "chat-message-ts" }, small(msg.ts.toLocaleTimeString())),
  );
  return el;
};

export class DefaultUiChatElement extends HTMLElement {
  session: ChatSession | undefined;

  msgs: ChatMessage[] = vanX.reactive([]);
  sessionStartLastMessage: ChatMessage | undefined;
  isOnCall = van.state(false);

  errorText = van.state("");
  loadedAllOldMessages = false;

  constructor(receiver: string, lastMessage: ChatMessage | undefined) {
    super();
    this.setAttribute("receiver", receiver);
    this.sessionStartLastMessage = lastMessage;
  }

  disconnectedCallback() {
    this.session?.close();
    this.session = undefined;
  }

  connectedCallback() {
    this.render();
  }

  get receiver() {
    return this.getAttribute("receiver");
  }

  get sender() {
    return this.getAttribute("sender");
  }

  render() {
    const receiver = this.receiver!;
    const header = div(
      { class: "chat-element-header" },
      div(
        {
          class: "user-chat-header",
        },
        UserProfileElement(receiver),
        a(
          {
            class: "no-text-decoration",
            href: `/user/${receiver}`,
            onclick: (e: Event) => {
              e.preventDefault();
              routeNavigator.pushRoute(`/user/${receiver}`);
            },
          },
          b(receiver),
        ),
      ),

      button(
        {
          class: "theme-button no-button-decoration",
          onclick() {
            Utils.showSnackBar("Not yet implemented");
          },
        },
        () => (!this.isOnCall.val ? UiIcon("phone") : UiIcon("phone-off")),
      ),

      button(
        {
          class: "theme-button no-button-decoration delete-chat-btn",
          onclick() {
            Utils.showDialog(
              div("Confirm Delete This Conversation?"),
              [
                {
                  text: "Yes, Delete",
                  onClick: () => {
                    deleteChat(receiver);
                    window.history.back();
                  },
                  class: "red-button",
                },
                {
                  text: "Cancel",
                  onClick() {},
                  class: "theme-button",
                },
              ],
              () => {},
            );
          },
        },
        UiIcon("trash2"),
      ),
    );
    const user = AuthHandler.currentUser();

    const el = ul({ class: "no-pad no-list-style" });
    const i = input({
      class: "theme-input msg-input-field",
      onkeydown: (e: KeyboardEvent) => {
        if (e.key == "Enter") {
          this.submitMessage();
        }
      },
    });

    const inputField = div(
      { class: "chat-input-field" },
      i,
      button(
        {
          class: "no-button-decoration",
          onclick: () => {
            this.submitMessage();
          },
        },
        UiIcon("send-horizontal"),
      ),
    );
    const page = div(() => {
      if (user.val != null) {
        return Await(
          {
            value: AuthHandler.getUsername(user.val),
            Loading() {
              return div({ class: "loader" });
            },
          },
          (value) => {
            if (value) {
              this.startSession();
              return div(el, () =>
                this.errorText.val.length > 0
                  ? div({ class: "error" }, this.errorText.val)
                  : div(),
              );
            } else {
              return div("User is not signed in");
            }
          },
        );
      }
      return div();
    });

    this.replaceChildren(header, page, inputField);
    this.classList.add("chat-element");
  }

  async startSession() {
    if (this.session) {
      return;
    }
    const username = await AuthHandler.getUsername();
    if (!username) {
      this.errorText.val = "User not signed in";
      return;
    }

    this.setAttribute("sender", username);
    const receiver = this.receiver;
    if (!receiver) {
      this.errorText.val = "Attribute not set 'receiver'";
      return;
    }

    this.session = new ChatSession(
      username,
      receiver,
      (msg) => this.addMessageElement(msg),
      (err) => {
        console.error(err);
        this.errorText.val = `${err}`;
      },
      () => {
        const list = this.querySelector("ul")!;
        list.addEventListener("scroll", (_) => {
          if (list.scrollTop === 0 && !this.loadedAllOldMessages) {
            let lastTimeMessagesLength = this.msgs.length;
            this.getOlderMessages().finally(() => {
              if (lastTimeMessagesLength === this.msgs.length) {
                this.loadedAllOldMessages = true;
              }
            });
          }
        });
      },
      this.sessionStartLastMessage,
    );
  }

  addMessageElement(msg: ChatMessage) {
    const list = this.querySelector("ul")!;
    const child = li(messageElement(msg, this.receiver ?? ""));
    child.setAttribute("data-msg-id", msg.id);

    if (this.msgs.length == 0 || this.msgs[this.msgs.length - 1].id < msg.id) {
      list.append(child);
      this.msgs.push(msg);
      setTimeout(() => child.scrollIntoView({ behavior: "smooth" }), 100);
    } else {
      let i = 0;
      for (; i < this.msgs.length; i++) {
        if (this.msgs[i].id > msg.id) {
          break;
        }
        if (this.msgs[i].id === msg.id) {
          return;
        }
      }

      const before = list.querySelector(`[data-msg-id="${this.msgs[i].id}"]`);
      list.insertBefore(child, before);
      this.msgs.splice(i, 0, msg);
    }
    let dateElement = list.querySelector(
      `[data-date="${msg.ts.toLocaleDateString()}"]`,
    );
    if (!dateElement) {
      dateElement = li({ class: "row-center" }, small(msg.ts.toDateString()));
      dateElement.setAttribute("data-date", msg.ts.toLocaleDateString());
      child.insertAdjacentElement("afterbegin", dateElement);
    }
  }

  async getOlderMessages() {
    if (this.msgs.length > 0 && this.session) {
      const oldestMessage = this.msgs[0];
      console.log(
        `Getting older messages than ${JSON.stringify(oldestMessage)}`,
      );
      const messages = await this.session.getPreviousChunk(oldestMessage.id, 1);
      for (const message of messages) {
        this.addMessageElement(message);
      }
    }
  }

  async sendMessage(msg: string) {
    if (msg.length == 0) {
      return;
    }
    return this.session?.sendMessage(msg).then((msg) => {
      if (msg) {
        this.addMessageElement(msg);
      }
    });
  }

  submitMessage() {
    const i = this.querySelector(".msg-input-field") as HTMLInputElement;
    this.sendMessage(i.value);
    i.value = "";
  }
}

customElements.define("default-chat-element", DefaultUiChatElement);
