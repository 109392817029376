import van from "vanjs-core";
const { div } = van.tags;

export const dropDownMenu = (title: HTMLElement, tiles: HTMLElement[]) => {
  tiles.forEach((tile) => tile.classList.add("drop-down-tile"));
  title.classList.add("drop-down-btn");
  const show = van.state(false);

  title.addEventListener("click", (_) => {
    show.val = !show.val;
  });
  return div(
    { class: "drop-down-menu" },
    title,
    div({ class: () => `drop-down-tiles ${show.val ? "show" : ""}` }, ...tiles),
  );
};
