import { RouteBuilder } from "./navigator";
import van from "vanjs-core";
import { User } from "../protos/user";
import { DefaultUiChatElement } from "../chats/defaultUi";
import { getUser } from "../api/user";
import { FollowsPage } from "./followsPage";
const { div } = van.tags;

export namespace RouteBuilders {
  export const Settings: RouteBuilder = async (url, _) => {
    if (new URL(url).pathname !== "/settings") {
      return undefined;
    }

    const { SettingsPage } = await import("./settings");

    return SettingsPage();
  };

  export const Login: RouteBuilder = async (url, _) => {
    if (new URL(url).pathname !== "/signin") {
      return undefined;
    }
    const { LoginPage } = await import("./loginPage");

    return new LoginPage();
  };

  export const CreatePost: RouteBuilder = async (url, data) => {
    const uri = new URL(url);
    if (uri.pathname !== "/create-post") {
      return undefined;
    }

    const { CreatePostElement, CreatePostType } = await import(
      "../customElements/create-post"
    );

    const createdPostCallback = data ? data["onCreateCallback"] : undefined;

    if (data && "post" in data) {
      if ("reply" in data && data.reply === true) {
        return new CreatePostElement(
          data.post,
          CreatePostType.replying,
          createdPostCallback,
        );
      } else if ("edit" in data && data.edit === true) {
        return new CreatePostElement(
          data.post,
          CreatePostType.editing,
          createdPostCallback,
        );
      }
    }
    return new CreatePostElement(undefined, undefined, createdPostCallback);
  };

  export const Search: RouteBuilder = async (url, _) => {
    const uri = new URL(url);

    if (uri.pathname !== "/search") {
      return undefined;
    }

    const query = uri.searchParams.get("q")?.trim();
    if (!query || query.length === 0) {
      const { div, p } = van.tags;
      return div(p("Search Query is Empty"));
    }

    const { searchPageElement } = await import("./searchPage");

    return searchPageElement(query);
  };

  export const SignUp: RouteBuilder = async (url, _) => {
    if (new URL(url).pathname !== "/signup") {
      return undefined;
    }

    const { SignUpPage } = await import("./signUpPage");

    return new SignUpPage();
  };

  export const User: RouteBuilder = async (url: string, data: any) => {
    const parsedUrl = new URL(url);
    if (!parsedUrl.pathname.startsWith("/user/")) {
      return undefined;
    }

    const { UserPage } = await import("./userPage");

    if (data && "user" in data) {
      const user = data.user as User;
      return UserPage(user);
    }

    const username = parsedUrl.pathname.split("/")[2];

    if (username !== null) {
      const user = await getUser(username);
      if (user) {
        return UserPage(user);
      } else {
        return div({ class: "error center" }, `No User ${username} found`);
      }
    }
  };

  export const Chat: RouteBuilder = async (url: string, data: any) => {
    const parsedUrl = new URL(url);
    if (!parsedUrl.pathname.startsWith("/chat/")) {
      return undefined;
    }
    const receiver = parsedUrl.pathname.split("/")[2];

    return div(
      { class: "chat-page" },
      div(
        { class: "row-center" },
        new DefaultUiChatElement(
          receiver,
          data && "lastMessage" in data ? data["lastMessage"] : undefined,
        ),
      ),
    );
  };

  export const Follows: RouteBuilder = async (url: string, _data: any) => {
    const parsedUrl = new URL(url);
    if (!parsedUrl.pathname.startsWith("/follows")) {
      return undefined;
    }

    return FollowsPage();
  };
}
