import { areListsEqual } from "../bin/utils";
import { HyperLinkElement, UsernameElement } from "./hyper-link";
import van from "vanjs-core";

export class PatternMatcher {
  matcher: (_: string) => Match[];
  delimiter: (_: string) => string;
  matchType: string;
  lookInwards: boolean;
  singleType: boolean;

  constructor(
    matcher: (_: string) => Match[],
    delimiter: (_: string) => string,
    matchType: string,
    lookInwards: boolean,
    singleType: boolean,
  ) {
    this.matchType = matchType;
    this.matcher = matcher;
    this.delimiter = delimiter;
    this.lookInwards = lookInwards;
    this.singleType = singleType;
  }

  toString() {
    return `PatternMatcher { type: ${this.matchType} }`;
  }
}

export class RegexPatternMatcher extends PatternMatcher {
  regex: RegExp;

  constructor(
    regex: RegExp,
    matchType: string,
    delimiter: (_: string) => string,
    lookInwards: boolean,
    singleType: boolean,
  ) {
    const matcher = (_: string) => {
      let matchArray: RegExpExecArray | null;
      let matches: Match[] = [];
      regex.lastIndex = 0;
      while ((matchArray = regex.exec(_)) !== null) {
        const match = new Match(
          matchArray.index,
          matchArray.index + matchArray[0].length,
          _,
        );
        matches.push(match);
      }
      return matches;
    };
    super(matcher, delimiter, matchType, lookInwards, singleType);
    this.regex = regex;
  }
}

class PatternToHtmlContainer {
  matchType: string;
  converter: () => HTMLElement;

  constructor(matchType: string, converter: () => HTMLElement) {
    this.matchType = matchType;
    this.converter = converter;
  }
  wrap(_: string | HTMLElement) {
    const container = this.converter();
    if (typeof _ === "string") {
      container.innerText = _;
    } else {
      container.appendChild(_);
    }
    return container;
  }
}
interface Tuple<U, V> {
  a: U;
  b: V;
}

class Match {
  start: number;
  end: number;
  inputText: string;

  constructor(start: number, end: number, inputText: string) {
    this.start = start;
    this.end = end;
    this.inputText = inputText;
  }

  result() {
    return this.inputText.substring(this.start, this.end);
  }
}

export class PatternMatchPart {
  text: string;
  matchTypes: string[];

  constructor(text: string, matchTypes: string[] = []) {
    this.text = text;
    this.matchTypes = matchTypes;
  }

  toString() {
    return `{ text: ${this.text}, type: ${this.matchTypes} }`;
  }

  isEqual(other: PatternMatchPart) {
    if (other === this) {
      return true;
    }
    return (
      other.text === this.text &&
      areListsEqual(other.matchTypes, this.matchTypes)
    );
  }
}

export const rawBoldRegex = /(?<!\\)\*\*\*(.*?)(?<!\\)\*\*\*/gm;
export const rawItalicRegex = /(?<!\\)\/\/\/(.*?)(?<!\\)\/\/\//gm;
export const rawUnderlineRegex = /(?<!\\)___(.*?)(?<!\\)___/gm;
export const rawHeaderRegex = /(?<!\\)###(.*?)(?<!\\)###/gm;
export const rawCodeRegex = /(?<!\\)"""(.*?)(?<!\\)"""/gm;
export const rawHashtagRegex = /(?<!\\)#\w+/gm;
export const rawMentionRegex = /(?<!\\)@\w+/gm;

export const rawUsernameRegex = /^[0-9a-zA-Z_]{3,30}$/gm;
export const rawQuoteRegex = /^>\|\s.*$/gm;
// export const rawHyperLinkRegex = /\[(.+)\]\((.+)\)/gm
export const rawHyperLinkRegex = /\[[^\]]+\]\([^)]+\)/gm;
export const rawEmailRegex = /[\w-\.]+@([\w-]+\.)+[\w-]{2,4}/gm;

export const tripleDelimiterBoth = (_: string) => _.substring(3, _.length - 3);
export const singleDelimiter = (_: string) => _.substring(1);
export const noDelimiter = (_: string) => _;

function defaultMatchers() {
  const boldMatcher = new RegexPatternMatcher(
    rawBoldRegex,
    "bold",
    tripleDelimiterBoth,
    true,
    false,
  );
  const headerMatcher = new RegexPatternMatcher(
    rawHeaderRegex,
    "header",
    tripleDelimiterBoth,
    true,
    false,
  );
  const codeMatcher = new RegexPatternMatcher(
    rawCodeRegex,
    "code",
    tripleDelimiterBoth,
    true,
    false,
  );
  const italicMatcher = new RegexPatternMatcher(
    rawItalicRegex,
    "italic",
    tripleDelimiterBoth,
    true,
    false,
  );
  const underlineMatcher = new RegexPatternMatcher(
    rawUnderlineRegex,
    "underline",
    tripleDelimiterBoth,
    true,
    false,
  );
  const hashtagMatcher = new RegexPatternMatcher(
    rawHashtagRegex,
    "hashtag",
    singleDelimiter,
    false,
    true,
  );
  const usernameMatcher = new RegexPatternMatcher(
    rawMentionRegex,
    "username",
    singleDelimiter,
    false,
    true,
  );
  const hyperLinkMatcher = new RegexPatternMatcher(
    rawHyperLinkRegex,
    "hyperlink",
    noDelimiter,
    false,
    true,
  );
  const quoteMatcher = new RegexPatternMatcher(
    rawQuoteRegex,
    "quote",
    tripleDelimiterBoth,
    true,
    true,
  );
  return [
    boldMatcher,
    headerMatcher,
    hashtagMatcher,
    italicMatcher,
    usernameMatcher,
    hyperLinkMatcher,
    quoteMatcher,
    underlineMatcher,
    codeMatcher,
  ];
}

function defaultHTMLContainerConverters() {
  const underlineConverter = new PatternToHtmlContainer("underline", () =>
    document.createElement("u"),
  );

  const quoteConverter = new PatternToHtmlContainer("bold", () => {
    const b = document.createElement("b");
    b.style.color = "pink";
    return b;
  });

  const codeConverter = new PatternToHtmlContainer("code", () => {
    const div = document.createElement("div");
    div.style.fontFamily = "'Courier New', monospace";
    return div;
  });
  const boldConverter = new PatternToHtmlContainer("bold", () =>
    document.createElement("b"),
  );

  const headerConverter = new PatternToHtmlContainer("header", () => {
    const header = document.createElement("h1");
    header.style.display = "inline";
    return header;
  });

  const hashtagConverter = new PatternToHtmlContainer("hashtag", () => {
    const hashtag = document.createElement("b");
    hashtag.style.color = "blue";
    return hashtag;
  });

  const italicConverter = new PatternToHtmlContainer("italic", () => {
    const italic = document.createElement("span");
    italic.style.fontStyle = "italic";
    return italic;
  });

  const usernameConverter = new PatternToHtmlContainer(
    "username",
    () => new UsernameElement(),
  );

  const hyperLinkConverter = new PatternToHtmlContainer(
    "hyperlink",
    () => new HyperLinkElement(),
  );

  return [
    boldConverter,
    headerConverter,
    hashtagConverter,
    italicConverter,
    usernameConverter,
    codeConverter,
    hyperLinkConverter,
    quoteConverter,
    underlineConverter,
  ];
}

export function parseText(
  inputPart: PatternMatchPart,
  patternMatchers: PatternMatcher[],
) {
  const inputText = inputPart.text;
  const parts: PatternMatchPart[] = [];
  if (patternMatchers.length === 0) {
    return [inputPart];
  }
  const patternMatches: Tuple<Match, PatternMatcher>[] = [];
  for (const patternMatcher of patternMatchers) {
    patternMatches.push(
      ...patternMatcher
        .matcher(inputText)
        .map((e) => ({ a: e, b: patternMatcher })),
    );
  }

  patternMatches.sort((a, b) => a.a.start - b.a.start);

  let current = 0;
  let currentTypes = [...inputPart.matchTypes];

  for (const match of patternMatches) {
    if (current > match.a.start) {
      continue;
    }

    if (current < match.a.start) {
      const part = new PatternMatchPart(
        inputText.substring(current, match.a.start),
        currentTypes,
      );
      const result = parseText(part, patternMatchers);
      parts.push(...result);
    }

    const matchTypes = match.b.singleType
      ? [match.b.matchType]
      : [...currentTypes, match.b.matchType];
    const part = new PatternMatchPart(
      match.b.delimiter(inputText.substring(match.a.start, match.a.end)),
      matchTypes,
    );

    if (match.b.lookInwards) {
      const result = parseText(part, patternMatchers);
      parts.push(...result);
    } else {
      parts.push(part);
    }

    current = match.a.end;
  }

  if (current < inputText.length) {
    const input = inputText.substring(current);
    const part = new PatternMatchPart(input, currentTypes);
    parts.push(part);
  }

  return parts;
}

export function convertPatternMatchesToHtmlElementsContainers(
  matches: PatternMatchPart[],
  converters: PatternToHtmlContainer[],
): HTMLElement {
  const children: HTMLElement[] = [];
  // const div = document.createElement("div")
  for (const match of matches) {
    if (match.matchTypes.length === 0) {
      const p = van.tags.span(match.text);
      children.push(p);
    } else {
      let innerChild: HTMLElement | undefined;
      const reversedMatchTypes = match.matchTypes.reverse();
      for (const matchType of reversedMatchTypes) {
        const converter = converters.find((_) => _.matchType === matchType);
        if (converter) {
          innerChild = converter.wrap(innerChild ?? match.text);
        } else {
          const p = document.createElement("span");
          if (innerChild) {
            p.appendChild(innerChild);
          } else {
            p.innerText = match.text;
          }
          innerChild = p;
        }
      }
      if (innerChild) {
        children.push(innerChild);
      }
    }
  }
  return van.tags.div(...children);
}

const _matchers = defaultMatchers();
const _converters = defaultHTMLContainerConverters();

export function parseTextToHtmlElement(text: string): HTMLElement {
  const patternMatches = parseText(new PatternMatchPart(text), _matchers);
  const elem = convertPatternMatchesToHtmlElementsContainers(
    patternMatches,
    _converters,
  );
  return elem;
}
