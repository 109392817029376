import { LupydHeader } from "../customElements/headers/lupyd-header";
import { PostFeed } from "../customElements/post-feed";
import van from "vanjs-core";
import { getFollowedUsersState } from "../doc/user";
import { UserProfileElement } from "./userPage";
import { routeNavigator } from "./navigator";
import { isMobileResolution, UiIcon } from "../bin/utils";
import { ChatList } from "../chats/chatList";
import { FollowUserButton } from "./searchPage";
import { RootSideBar } from "../customElements/sidebar";

const { div, ul, h2, a, button } = van.tags;

export class LupydHomePage extends HTMLElement {
  connectedCallback() {
    this.render();
  }

  render() {
    const feed = new PostFeed();

    const showChatList = van.state(false);
    this.replaceChildren(
      LupydHeader(undefined, undefined, showChatList, (post) =>
        feed.addPost(post),
      ),
      div(
        { class: "lupyd-feeds" },
        div(
          { class: "row" },
          () => (isMobileResolution.val ? div() : RootSideBar(showChatList)),
          feed,
          () => (showChatList.val ? ChatList() : div()),
        ),
      ),
    );
  }
}

customElements.define("lupyd-home-page", LupydHomePage);

export function UsersFollowingElement() {
  const followedUsers = getFollowedUsersState();

  return div(
    {
      class: "user-following-feed",
    },
    h2("Users you follow"),
    () =>
      ul(
        { class: "no-pad no-list-style" },
        ...followedUsers.val.map((username) =>
          div(
            { class: "row spread user-tile" },
            UserProfileElement(username),
            div(
              { class: "row-center" },
              a(
                {
                  class: "no-text-decoration",
                  href: `/user/${username}`,
                  onclick(event: Event) {
                    event.preventDefault(),
                      routeNavigator.pushRoute(`/user/${username}`);
                  },
                },
                username,
              ),
            ),
            FollowUserButton(username),

            button(
              {
                class: "theme-button no-button-decoration fill-primary-accent",
                onclick: () => {
                  routeNavigator.pushRoute(`/chat/${username}`);
                },
              },
              UiIcon("message-circle"),
            ),
          ),
        ),
      ),
  );
}
