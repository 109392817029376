import { User } from "../protos/user";

import van from "vanjs-core";
import { CDN_STORAGE, DEFAULT_USER_ICON } from "../constants";
import { postBodyElement, postElement } from "../customElements/post-div";
import { FullPost, PostBody } from "../protos/post";
import { FetchType, getPosts } from "../api/post";
import * as vanX from "vanjs-ext";
import { FollowUserButton } from "./searchPage";
import { LupydHeader } from "../customElements/headers/lupyd-header";
import { UiIcon, ulidStringify } from "../bin/utils";
import { routeNavigator } from "./navigator";
import store from "store2";

const { ul, div, img, b, button } = van.tags;

export const UserProfileElement = (
  username: string,
  noPfp: boolean = false,
) => {
  const src =
    noPfp || username.length === 0
      ? DEFAULT_USER_ICON
      : `${CDN_STORAGE}/users/${username}`;
  return img({
    class: "user-profile-img",
    src,
    onerror() {
      (this as HTMLImageElement).src = DEFAULT_USER_ICON;
    },
    alt: `Profile of ${username}`,
    loading: "lazy",
  });
};

export const UserPage = (user: User) => {
  console.log({ body: PostBody.decode(user.bio) });
  const userPosts = vanX.reactive([] as FullPost[]);

  let start: string | undefined = undefined;
  let end: string | undefined = undefined;
  getPosts({
    fetchType: FetchType.Users,
    fetchTypeFields: user.uname,
    start,
    end,
  }).then((posts) => {
    userPosts.push(...posts.map(vanX.noreactive));
    const sortedPosts = posts.sort((a, b) => (a.id > b.id ? 1 : -1));
    if (sortedPosts.length > 0) {
      start = ulidStringify(sortedPosts[0].id);
    }
  });

  return div(
    { class: "user-page" },
    LupydHeader(),
    div(
      { class: "header-pad" },
      div(
        { class: "center" },
        div(
          { class: "post-div-wrapper" },
          div(
            { class: "user-element" },
            div(
              { class: "row user-header" },
              UserProfileElement(user.uname),
              div({ class: "row-center" }, b(user.uname)),
              FollowUserButton(user.uname),
              user.uname != store.get("username") && user.chats
                ? ChatButton(user.uname, "theme-button")
                : div(),
            ),
            user.bio.length === 0
              ? div()
              : postBodyElement(PostBody.decode(user.bio)),
          ),
        ),
      ),
    ),

    div(
      { class: "center" },
      div(
        { class: "post-feed" },
        vanX.list(ul({ class: "no-pad no-list-style" }), userPosts, (post) =>
          postElement(post.val),
        ),
      ),
    ),
  );
};

export function ChatButton(username: string, buttonClass: string) {
  return button(
    {
      class: buttonClass,
      onclick: () => {
        routeNavigator.pushRoute(`/chat/${username}`);
      },
    },
    UiIcon("message-circle"),
  );
}
