import { dateToRelativeString, Utils } from "../bin/utils";

export class DurationCounter extends HTMLElement {
  constructor(startFrom = new Date(), intervalDuration = 1000) {
    super();
    this.setAttribute("start-from", startFrom.toISOString());
    this.setAttribute("interval-duration", intervalDuration.toString());
  }

  connectedCallback() {
    const startFrom = new Date(this.getAttribute("start-from")!);
    const intervalId = setInterval(
      () => {
        const secondsPassed =
          new Date().getUTCSeconds() - startFrom.getUTCSeconds();
        this.innerText = `${secondsPassed}s`;
      },
      Math.min(50, Number(this.getAttribute("interval-duration"))),
    );

    this.setAttribute("interval-id", `${intervalId}`);
  }

  disconnectedCallback() {
    const intervalId = this.getAttribute("interval-id");
    if (intervalId != null) {
      clearInterval(Number(intervalId));
    }
  }
}

customElements.define("duration-counter", DurationCounter);

export class IntervalElement extends HTMLElement {
  onUpdateCallback: (_: IntervalElement) => void;

  constructor(
    intervalDurationInMs = 1000,
    onUpdateCallback: (_: IntervalElement) => void,
  ) {
    super();
    this.setAttribute("interval-duration", `${intervalDurationInMs}`);
    this.onUpdateCallback = onUpdateCallback;
  }

  disconnectedCallback() {
    const id = this.getAttribute("interval-id");
    if (id) {
      clearInterval(id);
    }
  }

  connectedCallback() {
    const id = setInterval(() => {
      this.onUpdateCallback(this);
    }, this.intervalDuration);
    this.setAttribute("interval-id", `${id}`);
  }

  get intervalDuration() {
    return Number(this.getAttribute("interval-duration"));
  }
}
customElements.define("interval-element", IntervalElement);
