import van from "vanjs-core";
import { routeNavigator } from "../routes/navigator";

const { button, span, a } = van.tags;

export const Button = (child: HTMLElement, onclick: () => void) =>
  button({ class: "theme-button", onclick }, child);

export const LinkButton = (
  child: HTMLElement,
  route: string,
  viaRouteNavigator: boolean = false,
) =>
  a(
    {
      href: route,
      class: "theme-button",
      onclick: viaRouteNavigator
        ? (e: Event) => {
            e.preventDefault();
            routeNavigator.pushRoute(route);
          }
        : null,
    },
    child,
  );

export const TextButton = (text: string, onclick: () => void) =>
  Button(span(text), onclick);
