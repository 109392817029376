import van from "vanjs-core";

const { h2, div, h1, p, a } = van.tags;

export const errorRouteBuilder = async (_: string, data: { err: Error }) => {
  const stackTrace = p();

  stackTrace.innerText = data.err.stack ?? "";

  return div(
    { class: "error-element" },
    div(
      { class: "error-element-container" },
      h1("Oops! Something is broken"),
      div({ class: "stack-trace" }, h2(data.err.message), stackTrace),
      a({ href: "/" }, "Go To Home"),
    ),
  );
};
