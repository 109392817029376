import store from "store2";
import { postPageBuilder } from "./customElements/post-div";
import { LupydFirebaseElement } from "./firebase/element";
import { routeNavigator } from "./routes/navigator";
import { rootRouteBuilder } from "./routes/rootRoute";
import { AuthHandler } from "./firebase/auth";
import { isDarkMode, launchDeepLink } from "./bin/utils";
import { LupydDatabasesElement } from "./customElements/databases";
import { RouteBuilders } from "./routes/routes";
import { ASCII_LOGO } from "./constants";

document.body.append(new LupydFirebaseElement());
document.body.append(new LupydDatabasesElement());

window.addEventListener("hashchange", (_) => console.log("Hash Changed ", _));
window.addEventListener("popstate", (_) => routeNavigator.updatePage());
window.addEventListener("keydown", (_) => {
  if (_.key === "Escape") {
    console.log(`Pressed Escape`);
  }
});
window.addEventListener("message", (msg) => {
  console.log({ msg });
});
routeNavigator.addRouteBuilder(rootRouteBuilder);
routeNavigator.addRouteBuilder(postPageBuilder);
routeNavigator.addRouteBuilder(RouteBuilders.CreatePost);
routeNavigator.addRouteBuilder(RouteBuilders.Login);
routeNavigator.addRouteBuilder(RouteBuilders.User);
routeNavigator.addRouteBuilder(RouteBuilders.SignUp);
routeNavigator.addRouteBuilder(RouteBuilders.Search);
routeNavigator.addRouteBuilder(RouteBuilders.Settings);
routeNavigator.addRouteBuilder(RouteBuilders.Chat);
routeNavigator.addRouteBuilder(RouteBuilders.Follows);

function handleCurrentUrl() {
  const currentUrl = new URL(location.href);
  if (currentUrl.pathname.endsWith("/action")) {
    if (currentUrl.pathname.endsWith("/_m/action")) {
      const mobileDynamicLink = currentUrl.searchParams.get("fpdl"); // first page dynamic link
      if (mobileDynamicLink) {
        const mDUrl = decodeURIComponent(mobileDynamicLink);
        const url = new URL(mDUrl);
        url.searchParams.append("link", currentUrl.searchParams.get("link")!);
        launchDeepLink(url.toString());
        console.error(`Unimplemented Deep Link Handling`);
      }
    } else {
      AuthHandler.signIn(store.get("email"), location.href).then(
        async (user) => {
          if (await AuthHandler.getUsername(user)) {
            routeNavigator.replaceRoute("/");
          } else {
            routeNavigator.replaceRoute("/signup");
          }
        },
      );
    }
  } else {
    routeNavigator.replaceRoute(location.href);
  }
}

function checkTheme() {
  const savedMode: boolean | undefined = store.get("darkMode");
  if (typeof savedMode !== "boolean") {
    isDarkMode.val = matchMedia("(prefers-color-scheme: dark)").matches;
  } else {
    isDarkMode.val = savedMode;
  }
}

function replacePlaceHolder() {
  document.getElementById("init-placeholder")?.remove();
}

checkTheme();
handleCurrentUrl();
replacePlaceHolder();

console.log(ASCII_LOGO);
