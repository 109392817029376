// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v1.181.0
//   protoc               v5.27.3
// source: lupyd-md.proto

/* eslint-disable */
import * as _m0 from "protobufjs/minimal";

export const protobufPackage = "lupyd.markdown";

export enum ElementType {
  Normal = 0,
  Bold = 1,
  Italic = 2,
  Header = 4,
  UnderLine = 8,
  Code = 16,
  Quote = 32,
  Spoiler = 64,
  UNRECOGNIZED = -1,
}

export function elementTypeFromJSON(object: any): ElementType {
  switch (object) {
    case 0:
    case "Normal":
      return ElementType.Normal;
    case 1:
    case "Bold":
      return ElementType.Bold;
    case 2:
    case "Italic":
      return ElementType.Italic;
    case 4:
    case "Header":
      return ElementType.Header;
    case 8:
    case "UnderLine":
      return ElementType.UnderLine;
    case 16:
    case "Code":
      return ElementType.Code;
    case 32:
    case "Quote":
      return ElementType.Quote;
    case 64:
    case "Spoiler":
      return ElementType.Spoiler;
    case -1:
    case "UNRECOGNIZED":
    default:
      return ElementType.UNRECOGNIZED;
  }
}

export function elementTypeToJSON(object: ElementType): string {
  switch (object) {
    case ElementType.Normal:
      return "Normal";
    case ElementType.Bold:
      return "Bold";
    case ElementType.Italic:
      return "Italic";
    case ElementType.Header:
      return "Header";
    case ElementType.UnderLine:
      return "UnderLine";
    case ElementType.Code:
      return "Code";
    case ElementType.Quote:
      return "Quote";
    case ElementType.Spoiler:
      return "Spoiler";
    case ElementType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export enum HyperElementType {
  Mention = 0,
  HashTag = 1,
  Post = 2,
  Group = 3,
  Link = 4,
  UNRECOGNIZED = -1,
}

export function hyperElementTypeFromJSON(object: any): HyperElementType {
  switch (object) {
    case 0:
    case "Mention":
      return HyperElementType.Mention;
    case 1:
    case "HashTag":
      return HyperElementType.HashTag;
    case 2:
    case "Post":
      return HyperElementType.Post;
    case 3:
    case "Group":
      return HyperElementType.Group;
    case 4:
    case "Link":
      return HyperElementType.Link;
    case -1:
    case "UNRECOGNIZED":
    default:
      return HyperElementType.UNRECOGNIZED;
  }
}

export function hyperElementTypeToJSON(object: HyperElementType): string {
  switch (object) {
    case HyperElementType.Mention:
      return "Mention";
    case HyperElementType.HashTag:
      return "HashTag";
    case HyperElementType.Post:
      return "Post";
    case HyperElementType.Group:
      return "Group";
    case HyperElementType.Link:
      return "Link";
    case HyperElementType.UNRECOGNIZED:
    default:
      return "UNRECOGNIZED";
  }
}

export interface HyperCustomElement {
  tag: string;
  body: string;
}

export interface HyperElement {
  tag: HyperElementType;
  body: string;
}

export interface FileElement {
  filename: string;
  url: string;
  mimeType: string;
}

export interface PrimitiveElement {
  elementType: number;
  text: string;
}

export interface Element {
  primitiveElement?: PrimitiveElement | undefined;
  hyperElement?: HyperElement | undefined;
  hyperCustomElement?: HyperCustomElement | undefined;
  fileElement?: FileElement | undefined;
}

export interface Elements {
  elements: Element[];
}

export interface Markdown {
  elements: Elements | undefined;
}

function createBaseHyperCustomElement(): HyperCustomElement {
  return { tag: "", body: "" };
}

export const HyperCustomElement = {
  encode(message: HyperCustomElement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tag !== "") {
      writer.uint32(10).string(message.tag);
    }
    if (message.body !== "") {
      writer.uint32(18).string(message.body);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HyperCustomElement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHyperCustomElement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.tag = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.body = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HyperCustomElement {
    return {
      tag: isSet(object.tag) ? globalThis.String(object.tag) : "",
      body: isSet(object.body) ? globalThis.String(object.body) : "",
    };
  },

  toJSON(message: HyperCustomElement): unknown {
    const obj: any = {};
    if (message.tag !== "") {
      obj.tag = message.tag;
    }
    if (message.body !== "") {
      obj.body = message.body;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HyperCustomElement>, I>>(base?: I): HyperCustomElement {
    return HyperCustomElement.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HyperCustomElement>, I>>(object: I): HyperCustomElement {
    const message = createBaseHyperCustomElement();
    message.tag = object.tag ?? "";
    message.body = object.body ?? "";
    return message;
  },
};

function createBaseHyperElement(): HyperElement {
  return { tag: 0, body: "" };
}

export const HyperElement = {
  encode(message: HyperElement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.tag !== 0) {
      writer.uint32(8).int32(message.tag);
    }
    if (message.body !== "") {
      writer.uint32(18).string(message.body);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): HyperElement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseHyperElement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.tag = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.body = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): HyperElement {
    return {
      tag: isSet(object.tag) ? hyperElementTypeFromJSON(object.tag) : 0,
      body: isSet(object.body) ? globalThis.String(object.body) : "",
    };
  },

  toJSON(message: HyperElement): unknown {
    const obj: any = {};
    if (message.tag !== 0) {
      obj.tag = hyperElementTypeToJSON(message.tag);
    }
    if (message.body !== "") {
      obj.body = message.body;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<HyperElement>, I>>(base?: I): HyperElement {
    return HyperElement.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<HyperElement>, I>>(object: I): HyperElement {
    const message = createBaseHyperElement();
    message.tag = object.tag ?? 0;
    message.body = object.body ?? "";
    return message;
  },
};

function createBaseFileElement(): FileElement {
  return { filename: "", url: "", mimeType: "" };
}

export const FileElement = {
  encode(message: FileElement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.filename !== "") {
      writer.uint32(10).string(message.filename);
    }
    if (message.url !== "") {
      writer.uint32(18).string(message.url);
    }
    if (message.mimeType !== "") {
      writer.uint32(26).string(message.mimeType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): FileElement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseFileElement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.filename = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.url = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.mimeType = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): FileElement {
    return {
      filename: isSet(object.filename) ? globalThis.String(object.filename) : "",
      url: isSet(object.url) ? globalThis.String(object.url) : "",
      mimeType: isSet(object.mimeType) ? globalThis.String(object.mimeType) : "",
    };
  },

  toJSON(message: FileElement): unknown {
    const obj: any = {};
    if (message.filename !== "") {
      obj.filename = message.filename;
    }
    if (message.url !== "") {
      obj.url = message.url;
    }
    if (message.mimeType !== "") {
      obj.mimeType = message.mimeType;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<FileElement>, I>>(base?: I): FileElement {
    return FileElement.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<FileElement>, I>>(object: I): FileElement {
    const message = createBaseFileElement();
    message.filename = object.filename ?? "";
    message.url = object.url ?? "";
    message.mimeType = object.mimeType ?? "";
    return message;
  },
};

function createBasePrimitiveElement(): PrimitiveElement {
  return { elementType: 0, text: "" };
}

export const PrimitiveElement = {
  encode(message: PrimitiveElement, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.elementType !== 0) {
      writer.uint32(8).uint32(message.elementType);
    }
    if (message.text !== "") {
      writer.uint32(18).string(message.text);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PrimitiveElement {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePrimitiveElement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.elementType = reader.uint32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.text = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): PrimitiveElement {
    return {
      elementType: isSet(object.elementType) ? globalThis.Number(object.elementType) : 0,
      text: isSet(object.text) ? globalThis.String(object.text) : "",
    };
  },

  toJSON(message: PrimitiveElement): unknown {
    const obj: any = {};
    if (message.elementType !== 0) {
      obj.elementType = Math.round(message.elementType);
    }
    if (message.text !== "") {
      obj.text = message.text;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<PrimitiveElement>, I>>(base?: I): PrimitiveElement {
    return PrimitiveElement.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<PrimitiveElement>, I>>(object: I): PrimitiveElement {
    const message = createBasePrimitiveElement();
    message.elementType = object.elementType ?? 0;
    message.text = object.text ?? "";
    return message;
  },
};

function createBaseElement(): Element {
  return {
    primitiveElement: undefined,
    hyperElement: undefined,
    hyperCustomElement: undefined,
    fileElement: undefined,
  };
}

export const Element = {
  encode(message: Element, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.primitiveElement !== undefined) {
      PrimitiveElement.encode(message.primitiveElement, writer.uint32(10).fork()).ldelim();
    }
    if (message.hyperElement !== undefined) {
      HyperElement.encode(message.hyperElement, writer.uint32(18).fork()).ldelim();
    }
    if (message.hyperCustomElement !== undefined) {
      HyperCustomElement.encode(message.hyperCustomElement, writer.uint32(26).fork()).ldelim();
    }
    if (message.fileElement !== undefined) {
      FileElement.encode(message.fileElement, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Element {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseElement();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.primitiveElement = PrimitiveElement.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.hyperElement = HyperElement.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.hyperCustomElement = HyperCustomElement.decode(reader, reader.uint32());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.fileElement = FileElement.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Element {
    return {
      primitiveElement: isSet(object.primitiveElement) ? PrimitiveElement.fromJSON(object.primitiveElement) : undefined,
      hyperElement: isSet(object.hyperElement) ? HyperElement.fromJSON(object.hyperElement) : undefined,
      hyperCustomElement: isSet(object.hyperCustomElement)
        ? HyperCustomElement.fromJSON(object.hyperCustomElement)
        : undefined,
      fileElement: isSet(object.fileElement) ? FileElement.fromJSON(object.fileElement) : undefined,
    };
  },

  toJSON(message: Element): unknown {
    const obj: any = {};
    if (message.primitiveElement !== undefined) {
      obj.primitiveElement = PrimitiveElement.toJSON(message.primitiveElement);
    }
    if (message.hyperElement !== undefined) {
      obj.hyperElement = HyperElement.toJSON(message.hyperElement);
    }
    if (message.hyperCustomElement !== undefined) {
      obj.hyperCustomElement = HyperCustomElement.toJSON(message.hyperCustomElement);
    }
    if (message.fileElement !== undefined) {
      obj.fileElement = FileElement.toJSON(message.fileElement);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Element>, I>>(base?: I): Element {
    return Element.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Element>, I>>(object: I): Element {
    const message = createBaseElement();
    message.primitiveElement = (object.primitiveElement !== undefined && object.primitiveElement !== null)
      ? PrimitiveElement.fromPartial(object.primitiveElement)
      : undefined;
    message.hyperElement = (object.hyperElement !== undefined && object.hyperElement !== null)
      ? HyperElement.fromPartial(object.hyperElement)
      : undefined;
    message.hyperCustomElement = (object.hyperCustomElement !== undefined && object.hyperCustomElement !== null)
      ? HyperCustomElement.fromPartial(object.hyperCustomElement)
      : undefined;
    message.fileElement = (object.fileElement !== undefined && object.fileElement !== null)
      ? FileElement.fromPartial(object.fileElement)
      : undefined;
    return message;
  },
};

function createBaseElements(): Elements {
  return { elements: [] };
}

export const Elements = {
  encode(message: Elements, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.elements) {
      Element.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Elements {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseElements();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.elements.push(Element.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Elements {
    return {
      elements: globalThis.Array.isArray(object?.elements) ? object.elements.map((e: any) => Element.fromJSON(e)) : [],
    };
  },

  toJSON(message: Elements): unknown {
    const obj: any = {};
    if (message.elements?.length) {
      obj.elements = message.elements.map((e) => Element.toJSON(e));
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Elements>, I>>(base?: I): Elements {
    return Elements.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Elements>, I>>(object: I): Elements {
    const message = createBaseElements();
    message.elements = object.elements?.map((e) => Element.fromPartial(e)) || [];
    return message;
  },
};

function createBaseMarkdown(): Markdown {
  return { elements: undefined };
}

export const Markdown = {
  encode(message: Markdown, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.elements !== undefined) {
      Elements.encode(message.elements, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Markdown {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMarkdown();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.elements = Elements.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): Markdown {
    return { elements: isSet(object.elements) ? Elements.fromJSON(object.elements) : undefined };
  },

  toJSON(message: Markdown): unknown {
    const obj: any = {};
    if (message.elements !== undefined) {
      obj.elements = Elements.toJSON(message.elements);
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<Markdown>, I>>(base?: I): Markdown {
    return Markdown.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<Markdown>, I>>(object: I): Markdown {
    const message = createBaseMarkdown();
    message.elements = (object.elements !== undefined && object.elements !== null)
      ? Elements.fromPartial(object.elements)
      : undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | bigint | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}
