import { Slide, Slides } from "../dom/slides"
import { routeNavigator } from "../routes/navigator"
import van from "vanjs-core"

export class HyperLinkElement extends HTMLElement {
  static observedAttributes = ["input-text"]

  connectedCallback() {
    this.render(this.getAttribute("input-text") ?? this.innerText)
  }

  attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null) {
    if (name === "input-text" && oldValue !== newValue) {
      this.render(newValue ?? "")
    }
  }

  render(inputText: string) {
    const regex = new RegExp(/\[([^\]]+)\]\(([^)]+)\)/gm)
    // const innerText = this.innerText
    // const innerText = this.getAttribute("input-text") ?? ""
    if (inputText.length !== 0) {
      let matchArray: RegExpExecArray | null
      while ((matchArray = regex.exec(inputText)) !== null) {
        if (matchArray.length === 3) {
          const url = matchArray[2]
          const tag = matchArray[1]

          let child: HTMLElement
          switch (tag) {
            case "image":
              // child = createDomElement("img", { attr: { src: url, loading: "lazy" } })

              child = van.tags.img({ src: url, loading: "lazy" })

              child.style.display = "block"
              break
            case "video":
              // child = createDomElement("video", {
              //     attr: {
              //         controls: "true", src: url
              //     }
              // })
              child = van.tags.video({ src: url, controls: "true" })
              child.style.display = "block"
              break
            case "slides":
              const urls = url.split("|")
              const slideArray: Slide[] = []
              for (const url in urls) {
                if (url.startsWith("img:")) {
                  slideArray.push(new Slide(van.tags.img({ src: url.substring(4) })))
                  // slideArray.push(new Slide(createDomElement("img", { attr: { src: url.substring(4) } })))
                } else if (url.startsWith("vid:")) {
                  slideArray.push(new Slide(van.tags.video({ src: url.substring(4) })))
                  // slideArray.push(new Slide(createDomElement("video", { attr: { src: url.substring(4) } })))
                } else {
                  console.error("Unknown slide format ", url)
                }
              }
              const slides = new Slides(slideArray)
              child = slides
              break
            default:
              // const a = createDomElement("a") as HTMLAnchorElement
              // a.innerText = tag
              // a.href = url
              // a.target = "_blank"
              // child = a
              const a = van.tags.a({ href: url }, tag)
              child = a

              a.addEventListener("click", _ => {
                _.preventDefault()
                const confirmAlert = confirm(`Do you trust ${url}?`)
                if (confirmAlert) {
                  window.open(url, "_blank")
                }
              })

          }
          this.replaceChildren(child)
        }
      }
    }
  }
}

customElements.define("hyper-link", HyperLinkElement)

export class UsernameElement extends HTMLElement {
  connectedCallback() {
    this.render()
  }

  render() {
    const text = this.innerText
    const href = `/user/${text.substring(1)}`
    const a = van.tags.a({ href }, text)
    // const a = createDomElement('a', {
    //     text,
    //     attr: { href }
    // }) as HTMLAnchorElement
    a.addEventListener("click", _ => {
      _.preventDefault()
      routeNavigator.pushRoute(href)
    })

    this.replaceChildren(a)
  }
}

customElements.define("lupyd-username", UsernameElement)
